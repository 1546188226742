import Stack, { type StackProps } from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

type Props = StackProps & { count: number; suffix?: string }

export default function CreditsOverview({ count = 0, suffix, ...rest }: Props) {
    const intl = new Intl.NumberFormat('en-US')

    return (
        <Stack direction='row' alignItems='center' gap={2} {...rest}>
            <img src='/images/new-theme/enrichment/coins-2.png' alt='credits' style={{ width: '22px' }} />
            <Typography variant='newLabelSm' color='newNeutral'>
                {intl.format(count)} {suffix}
            </Typography>
        </Stack>
    )
}
