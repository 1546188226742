import type { Events, Conversions } from 'src/types/GTM'

const useGTMWrapper = () => {
    const tagManager = (window as any).gtag
    const sendGTMEvent = ({ event, data }: Events) => {
        if (typeof tagManager !== 'undefined') {
            tagManager('event', event, data)
        }
    }
    const sendGTMConversion = ({ data }: Conversions) => {
        if (typeof tagManager !== 'undefined') {
            tagManager('event', 'conversion', data) //inside gtag method definition we check for 2nd arg and if its 'conversion' we don't push it to dataLayer
        }
    }
    return { sendGTMEvent, sendGTMConversion }
}

export default useGTMWrapper

//* -We can type 'dataLayer' in chrome console and see gtm events with their payloads

//? Schema of different services codes:
// Google Tag Manager(GTM) --> GTM-XXXXXXX
// Google Analytics 4(GA4) --> G-XXXXXXXXXX
// Google Ads --> AW-XXXXXXXXXX
//? GTM --> Google Tag Manager , GTAG --> Google Global Site Tag(Uses for Google Ads,Google Analytics,...)
//? Use 3rd party libraries like 'react-gtm-module': yarn add react-gtm-module
//? Init gtm inside App.tsx or index.ts or create /public/index.html file and init gtm there:
// App.tsx : (react-gtm-module approach)
// import TagManager from 'react-gtm-module';
// TagManager.initialize({gtmId: 'GTM-XXXXXXX'});
//? For best performance load gtm even before react app gets initialize:
// /public/index.html: (approach without any external library)
// inside <head>:
// <script>
//   (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
//   new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
//   j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
//   'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
//   })(window,document,'script','dataLayer','GTM-XXXXXXX');
// </script>
// <script async src="https://www.googletagmanager.com/gtag/js?id=AW-XXXXXXX"></script>
// <script>
//     window.dataLayer = window.dataLayer || []
//     function gtag() {dataLayer.push(arguments)}
//     gtag('js', new Date())
//     gtag('config', 'AW-XXXXXXX')
// </script>
// inside <body>:
// <noscript>
//   <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-XXXXXXX"
//   height="0" width="0" style="display:none;visibility:hidden"></iframe>
// </noscript>
//? send gtm events:
// *with react-gtm-module:
// import TagManager from "react-gtm-module";
// const handleButtonClick = () => {
//   TagManager.dataLayer({
//     dataLayer: {event: "button_click",category: "User Actions",action: "Click",label: "Signup Button",},
//   });
// };
//* without react-gtm-module:
// if(typeof window.gtag !== 'undefined') window.gtag('event',<event-name>,<payload>);
//? track gtm conversions:
// if(typeof window.gtag !== 'undefined') window.gtag('event', 'conversion', <payload>);
