import MuiCircularProgress, { type CircularProgressProps } from '@mui/material/CircularProgress'

type MuiCircularProgressProps = Omit<CircularProgressProps, 'size'>
type Size = number | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl'
export type Props = MuiCircularProgressProps & {
    size?: Size
}

export default function CircularProgress({
    variant = 'indeterminate',
    value,
    color = 'newPrimary',
    size = 'md',
    thickness = 3,
    ...rest
}: Props) {
    const getSize = () => {
        switch (size) {
            case 'xs':
                return 16
            case 'sm':
                return 20
            case 'md':
                return 25
            case 'lg':
                return 32
            case 'xl':
                return 40
            case '2xl':
                return 50
            case '3xl':
                return 65
            default:
                return size
        }
    }
    const progressSize = getSize()

    return (
        <MuiCircularProgress
            variant={variant}
            value={value}
            color={color}
            size={progressSize}
            thickness={thickness}
            // sx={{color:'#f00'}}
            {...rest}
        />
    )
}
