import axios from 'src/libs/axios/v1'
import type {
    GetCreditPackages,
    GetCreditPackagesResponse,
    GetCreditsResponse,
    AddCreditsReqBody
} from 'src/types/Credit'

export const getCreditsPackages = async (): Promise<GetCreditPackages> => {
    const { data } = await axios.get<GetCreditPackagesResponse>('/credits')
    return {
        email: data.data.customer_email,
        items: data.data.credit_packs,
        code: data.code
    }
}
export const getCredits = async (): Promise<number> => {
    const { data } = await axios.get<GetCreditsResponse>('/common/get_credit')
    return data.data.credits
}
export const addCredits = async ({ email, amount }: AddCreditsReqBody) => {
    //! we don't use REST for checkout requests and we use <form method="POST" action={serverURL} /> for better security
    const fd = new FormData()
    fd.set('customer_email', email)
    fd.set('amount', `${amount}`)
    await axios.post('/credits/upgrade', fd) //will automatically redirect us to stripe from server
}
