import { useState, useRef, useEffect, useCallback } from 'react'
import Box, { type BoxProps } from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Button from 'src/components/shared/Button'
import Icon from 'src/components/shared/Icon'
import { type SxProps } from '@mui/material/styles'

type Props = BoxProps & {
    zoomMagnitude?: number
    minZoom?: number
    maxZoom?: number
    allowZoomIn?: boolean
    allowZoomOut?: boolean
    allowZoomReset?: boolean
    allowZoomFullscreen?: boolean
    sidebarPosition?: 'absolute' | 'sticky' | 'fixed'
    fullScreenSx?: SxProps
    contentSx?: SxProps
}

export default function ViewportManager({
    zoomMagnitude = 0.1, //amount of zoon-in,zoom-out level,
    minZoom = 0.2,
    maxZoom = 1.8,
    allowZoomIn = true,
    allowZoomOut = true,
    allowZoomReset = false,
    allowZoomFullscreen = true,
    children,
    sidebarPosition = 'absolute',
    fullScreenSx, //use for set custom style on fullscreen target if we are on fullscreen mode
    contentSx, //main content sx
    sx,
    className = '',
    ...rest
}: Props) {
    const container = useRef<HTMLDivElement>(null!)
    const [zoom, setZoom] = useState(1) //zoom level , 1 is without any zoom , less than 1 is zoom-out , more than 1 is zoom-in
    const [isFullscreen, setIsFullscreen] = useState(false)
    const zoomInHandler = () => {
        setZoom((old) => +Math.min(old + zoomMagnitude, maxZoom).toFixed(2))
    }
    const zoomOutHandler = () => {
        setZoom((old) => +Math.max(minZoom, old - zoomMagnitude).toFixed(2))
    }
    const resetZoomHandler = () => {
        setZoom(1)
    }
    const enterFullscreen = async () => {
        const elm = container.current
        if (elm && elm.requestFullscreen) {
            await elm.requestFullscreen()
        }
    }
    const exitFullscreen = async () => {
        if (document.fullscreenElement && document.exitFullscreen) {
            await document.exitFullscreen()
        }
    }
    const fullscreenChangeHandler = useCallback(() => {
        const newFullScreen = !!document.fullscreenElement
        setIsFullscreen(newFullScreen)
    }, [])
    useEffect(() => {
        document.addEventListener('fullscreenchange', fullscreenChangeHandler)
        return () => {
            document.removeEventListener('fullscreenchange', fullscreenChangeHandler)
        }
    }, [])
    return (
        <Box
            ref={container}
            maxWidth={1}
            overflow='auto' //use overflow auto to prevent any content cropping
            position='relative'
            //@ts-expect-error "merge 2 different sx props"
            sx={{ ...(isFullscreen ? fullScreenSx : undefined), ...sx }}
            className={`custom-scroll ${className}`}
            {...rest}
        >
            <Stack
                width={50}
                p={0}
                overflow='hidden'
                gap={0}
                border={1}
                borderColor='newNeutral.light5'
                borderRadius={1}
                bgcolor='white'
                position={sidebarPosition}
                left={3}
                top={3}
                zIndex={1}
                sx={{
                    '& > * + *': {
                        '&.MuiButtonBase-root': {
                            borderRadius: 0,
                            borderWidth: 0,
                            borderTopWidth: 1,
                            borderBottomWidth: 0,
                            borderStyle: 'solid',
                            borderColor: 'newNeutral.light5'
                        }
                    }
                }}
            >
                {allowZoomIn && (
                    <Button
                        variant='text'
                        color='newNeutral'
                        sx={{ minWidth: 0, px: 1, py: 3, borderRadius: 0 }}
                        onClick={zoomInHandler}
                    >
                        <Icon icon='mdi:plus' size='md' color='newNeutral' />
                    </Button>
                )}
                {allowZoomOut && (
                    <Button
                        variant='text'
                        color='newNeutral'
                        sx={{ minWidth: 0, px: 1, py: 3, borderRadius: 0 }}
                        onClick={zoomOutHandler}
                    >
                        <Icon icon='mdi:minus' size='md' color='newNeutral' />
                    </Button>
                )}
                {allowZoomReset && (
                    <Button
                        variant='text'
                        color='newNeutral'
                        sx={{ minWidth: 0, px: 1, py: 3, borderRadius: 0 }}
                        onClick={resetZoomHandler}
                    >
                        <Icon icon='mingcute:fullscreen-line' size='md' color='newNeutral' />
                    </Button>
                )}
                {allowZoomFullscreen && (
                    <Button
                        variant='text'
                        color='newNeutral'
                        sx={{ minWidth: 0, px: 1, py: 3, borderRadius: 0 }}
                        onClick={!isFullscreen ? enterFullscreen : exitFullscreen}
                    >
                        <Icon
                            icon={!isFullscreen ? 'mingcute:fullscreen-line' : 'mingcute:fullscreen-exit-line'}
                            size='md'
                            color='newNeutral'
                        />
                    </Button>
                )}
            </Stack>
            <Box
                sx={{
                    zoom,
                    ...contentSx
                }}
            >
                {children}
            </Box>
        </Box>
    )
}

//? Example:
{
    /* <ViewportManager mt={10} p={5} border={1} borderColor='black' height={400} fullScreenSx={{ bgcolor: 'white', }}>
    <p>...</p>
</ViewportManager> */
}
