import { Fragment } from 'react'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import { useTheme } from '@mui/material/styles'
import { useQuery, useMutation } from '@tanstack/react-query'
import Box, { type BoxProps } from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Chip from '@mui/material/Chip'
import Form from 'src/components/shared/Form'
import Button from 'src/components/shared/Button'
import Icon from 'src/components/shared/Icon'
import Link from 'src/components/shared/Link'
import Textfield from 'src/components/shared/Textfield'
import CountrySelect from 'src/components/wrappers/CountrySelect'
import Tooltip from 'src/components/shared/Tooltip'
import EnrichInput from 'src/components/new-enrichment/enrich/Input'
import CreditsOverview from 'src/components/commons/CreditsOverview'
import { getBulk, addEnrichment } from 'src/services/new-enrichment'
import useIntercomWrapper from 'src/hooks/useIntercomWrapper'
import useGTMWrapper from 'src/hooks/useGTMWrapper'
import type { ServiceCode, Service, EnrichForm } from 'src/types/enrichment'

type Props = Omit<BoxProps, 'onSubmit'> &
    Service & {
        bulkId: number
        onSubmit?: (code: ServiceCode) => void
    }

export default function EnrichServiceIO({
    bulkId,
    type,
    code,
    title,
    credits = 0,
    description,
    link,
    inputs = [],
    outputs = [],
    onSubmit,
    ...rest
}: Props) {
    const theme = useTheme()
    const { sendIntercomEvent } = useIntercomWrapper()
    const { sendGTMEvent } = useGTMWrapper()
    const { control, handleSubmit, reset } = useForm<EnrichForm>({
        mode: 'onSubmit',
        defaultValues: {
            countryCode: [{ label: 'United States', value: 'us' }],
            newBulkName: '',
            inputs: inputs.map((inp) => ({ ...inp, value: null }))
        }
    })
    const { fields: inputFields } = useFieldArray({
        control,
        name: 'inputs'
    })
    const hasCountryCode = code === 'CUF'
    const hasNewBulkName = code === 'FCL'
    const { isFetching: bulkLoading, data: bulk } = useQuery({
        initialData: null,
        queryKey: ['get-bulk', bulkId],
        queryFn: async () => {
            const bulk = await getBulk({ id: bulkId })
            return bulk
        }
    })
    const { isPending: enrichLoading, mutateAsync: addEnrich } = useMutation<unknown, Error, EnrichForm>({
        mutationFn: async ({ inputs = [], countryCode, newBulkName }) => {
            await addEnrichment({
                bulkId,
                service: code,
                inputs: inputs.map((inp) => inp.value?.value).filter((inp) => inp) as string[],
                countryCode: hasCountryCode ? countryCode?.[0]?.value : undefined,
                newBulkName: hasNewBulkName ? newBulkName : undefined
            })
        }
    })
    const onEnrichSubmit = async ({ inputs = [], countryCode, newBulkName }: EnrichForm) => {
        await addEnrich({ inputs, countryCode, newBulkName })
        sendIntercomEvent({
            event: 'used_service',
            data: { engine: 'enrichment', service: title, request_count: credits }
        })
        sendGTMEvent({
            event: 'list_enrichment',
            data: {
                service_name: 'enrichment',
                service_type: type,
                enrichment: title
            }
        })
        reset()
        if (onSubmit) onSubmit(code)
    }
    const inputOptions = bulk?.cellsDataNormalize.length
        ? bulk.cellsDataNormalize[0].map((header) => ({ label: `${header}`, value: `${header}` }))
        : []
    const inputTitles = inputs.map((input) => input.title.split(' or '))

    return (
        <Box display='flex' flexDirection='column' height={1} {...rest}>
            <Form
                onSubmit={handleSubmit(onEnrichSubmit)}
                style={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    gap: '1.5rem',
                    paddingBottom: '1.5rem'
                }}
            >
                <Box>
                    <Box px={6}>
                        <Box
                            px={3}
                            py={6}
                            borderRadius={1}
                            sx={{
                                backgroundImage: 'radial-gradient(circle 250px at 75% 0%, #FF7BCA88, #FFC56F38,#F3EFEC)'
                            }}
                        >
                            <Typography component='h2' variant='newLabelMd' color='newNeutral'>
                                About This Service
                            </Typography>
                            {!!description && (
                                <Typography component='p' variant='newBodySm' color='newNeutral.light2' mt={2}>
                                    {description}
                                </Typography>
                            )}
                            {link && (
                                <Box mt={6}>
                                    <Link
                                        target='_blank'
                                        href={link}
                                        size='xs'
                                        sx={{
                                            px: 6,
                                            boxShadow: 1,
                                            color: 'newNeutral.main',
                                            bgcolor: 'white',
                                            '&:hover': { boxShadow: 1 }
                                        }}
                                    >
                                        Learn More
                                    </Link>
                                </Box>
                            )}
                        </Box>
                    </Box>
                    <Divider sx={{ my: 6, borderColor: 'newNeutral.light5' }} />
                    <Box px={6}>
                        <Box mt={6}>
                            <Typography component='p' variant='newLabelMd' color='newNeutral'>
                                Valid Input
                            </Typography>
                            <Stack gap={2} mt={3}>
                                {inputTitles.map((titles, i) => (
                                    <Stack key={i} direction='row' gap={2} alignItems='center' flexWrap='wrap'>
                                        {titles.map((title, j) => (
                                            <Fragment key={title}>
                                                <Chip
                                                    variant='filled'
                                                    size='small'
                                                    label={
                                                        <Typography
                                                            component='span'
                                                            variant='newLabelSm'
                                                            color='newNeutral'
                                                        >
                                                            {title}
                                                        </Typography>
                                                    }
                                                    sx={{
                                                        height: 'auto',
                                                        px: 2,
                                                        py: 1,
                                                        bgcolor: 'newGrey.light4',
                                                        border: 1,
                                                        borderColor: 'newNeutral.light5'
                                                    }}
                                                />
                                                {j < titles.length - 1 && (
                                                    <Typography
                                                        component='span'
                                                        variant='newBodySm'
                                                        color='newNeutral.light5'
                                                    >
                                                        /
                                                    </Typography>
                                                )}
                                            </Fragment>
                                        ))}
                                    </Stack>
                                ))}
                            </Stack>
                        </Box>
                        <Box mt={8}>
                            <Typography component='p' variant='newLabelMd' color='newNeutral'>
                                Output
                            </Typography>
                            <Stack
                                mt={3}
                                direction='row'
                                bgcolor='newGrey.light4'
                                borderRadius={1}
                                border={1}
                                borderColor='newNeutral.light5'
                                p={4}
                                alignItems='center'
                                rowGap={4}
                                columnGap={2}
                                flexWrap='wrap'
                            >
                                {outputs.map((output, i) => (
                                    <Fragment key={output}>
                                        <Typography component='p' variant='newLabelSm' color='newNeutral'>
                                            {output}
                                        </Typography>
                                        {i < outputs.length - 1 && (
                                            <Typography component='p' variant='newLabelSm' color='newNeutral.light5'>
                                                /
                                            </Typography>
                                        )}
                                    </Fragment>
                                ))}
                            </Stack>
                            {/* <Typography
                                        component='p'
                                        variant='newLabelSm'
                                        color='newNeutral.light2'
                                        lineHeight={2.5}
                                        mt={3}
                                    >
                                        {outputs.join(' , ')}
                                    </Typography> */}
                        </Box>
                    </Box>
                    <Divider sx={{ my: 6, borderColor: 'newNeutral.light5' }} />
                    <Box px={6}>
                        <Stack direction='row' alignItems='center' gap={2}>
                            <Typography component='h3' variant='newLabelMd' color='newNeutral'>
                                Column Mapping
                            </Typography>
                            <Tooltip title='To put the right information in each column, just click on “Select” and pick the matching field. For example, if your spreadsheet has a column named “Company Websites”, choose as “Company Domain”.'>
                                <Icon icon='lucide:info' size='sm' color='newNeutral' style={{ display: 'flex' }} />
                            </Tooltip>
                        </Stack>
                        <Box mt={2}>
                            <List>
                                {inputFields.map((input, i) => (
                                    <ListItem key={input.id} sx={{ px: 0, py: 3 }}>
                                        <EnrichInput
                                            {...input}
                                            control={control}
                                            index={i}
                                            loading={bulkLoading}
                                            options={inputOptions}
                                            width={1}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                        {hasCountryCode && (
                            <Box mt={3}>
                                <Typography component='h4' variant='newLabelSm' color='newNeutral'>
                                    Country
                                </Typography>
                                <Controller
                                    control={control}
                                    name='countryCode'
                                    rules={{ required: 'Country is required' }}
                                    render={({ field, fieldState: { error } }) => (
                                        <CountrySelect
                                            size='xs'
                                            value={field.value || []}
                                            onChange={(_, newVal) => field.onChange(newVal)}
                                            placeholder='Select'
                                            color='newNeutral'
                                            error={!!error}
                                            helperText={error?.message}
                                            borderColor='newNeutral.light4'
                                            sx={{ mt: 4 }}
                                        />
                                    )}
                                />
                            </Box>
                        )}
                        {hasNewBulkName && (
                            <Box mt={3}>
                                <Typography component='h4' variant='newLabelSm' color='newNeutral'>
                                    New Bulk Name
                                </Typography>
                                <Controller
                                    control={control}
                                    name='newBulkName'
                                    rules={{ required: 'Name is required' }}
                                    render={({ field, fieldState: { error } }) => (
                                        <Textfield
                                            size='xs'
                                            {...field}
                                            color='newNeutral'
                                            placeholder='Enter '
                                            error={!!error}
                                            helperText={error?.message}
                                            borderColor='newNeutral.light4'
                                            sx={{ mt: 4 }}
                                        />
                                    )}
                                />
                            </Box>
                        )}
                    </Box>
                </Box>
                <Box px={6}>
                    <CreditsOverview count={credits} suffix='Credits Usage / Row' justifyContent='center' />
                    <Button
                        type='submit'
                        loading={enrichLoading}
                        variant='contained'
                        color='newNeutral'
                        size='xs'
                        fullWidth
                        sx={{ mt: 3 }}
                    >
                        Run Action
                    </Button>
                </Box>
            </Form>
        </Box>
    )
}
