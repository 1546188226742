import { useEffect } from 'react'

// ** react router imports
import { Outlet } from 'react-router-dom'

// ** store imports
import useUser from 'src/store/user'

// ** components imports
import AuthGuard from '../components/auth/AuthGuard'

// ** mui imports
import { styled } from '@mui/material/styles'
import Box, { BoxProps } from '@mui/material/Box'

// ** third party imports
import { useIntercom } from 'react-use-intercom'

// Styled component for Blank Layout component
const BlankLayoutWrapper = styled(Box)<BoxProps>(({ theme }) => ({
    height: '100vh',

    // For V1 Blank layout pages
    '& .content-center': {
        display: 'flex',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(5)
    },

    // For V2 Blank layout pages
    '& .content-right': {
        display: 'flex',
        minHeight: '100vh',
        overflowX: 'hidden',
        position: 'relative'
    }
}))

const BlankLayout = () => {
    const user = useUser((state) => state.user)
    const { update } = useIntercom()

    useEffect(() => {
        if (user) {
            update({
                name: user.firstName ? `${user.firstName} ${user.lastName}` : user.lastName,
                email: user.email,
                userHash: user.intercom_hash,
                createdAt: user.sign_up_date
            })
        }
    }, [])

    return (
        <AuthGuard>
            <BlankLayoutWrapper className='layout-wrapper'>
                <Box className='app-content' sx={{ minHeight: '100vh', overflowX: 'hidden', position: 'relative' }}>
                    <Outlet />
                </Box>
            </BlankLayoutWrapper>
        </AuthGuard>
    )
}

export default BlankLayout
