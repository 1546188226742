// ** models imports
import { ServiceModel } from 'src/shared/models'

export const services: Array<ServiceModel> = [
    {
        id: 13,
        service_name: 'Enrichment service',
        input: [['company name'], ['company domain']],
        output: 'company name, company domain, company emails, company phones, facebook, instagram, twitter, linkedin, youtube',
        mode: 'multi',
        service_code: 'All',
        video_link: 'https://www.youtube.com/embed/EX-Kas4M1lE',
        tag: 'popular',
        tag_color: 'warning',
        icon: '',
        available_integrations: ['hubspot', 'zoho', 'salesforce'],
        integration_type: 'company'
    },
    {
        id: 1,
        service_name: 'Company name to company domain',
        input: ['company name'],
        output: 'company domain',
        mode: 'single',
        service_code: 'CUF',
        video_link: 'https://www.youtube.com/embed/1IpEcq3Ls80',
        tag: 'popular',
        tag_color: 'warning',
        icon: '',
        available_integrations: ['hubspot', 'zoho', 'salesforce'],
        integration_type: 'contact'
    },
    {
        id: 27,
        service_name: 'CEOs email finder',
        input: ['company name'],
        output: 'company name, company domain, first name, last name, job title , job title level, email',
        mode: 'single',
        service_code: 'NTER',
        video_link: 'https://www.youtube.com/embed/Zl3pc-LSmjQ',
        tag: 'new',
        tag_color: 'success',
        icon: '/images/lordicon/best-seller.json',
        available_integrations: ['hubspot', 'zoho', 'salesforce'],
        integration_type: 'both'
    },
    {
        id: 2,
        service_name: 'Company name to company email',
        input: ['company name'],
        output: 'first name, last name, company name, company email, type, accuracy',
        mode: 'single',
        service_code: 'NTE',
        video_link: 'https://www.youtube.com/embed/0zMdxczE-BQ',
        tag: '',
        tag_color: '',
        icon: '',
        available_integrations: ['hubspot', 'zoho', 'salesforce'],
        integration_type: 'both'
    },
    {
        id: 3,
        service_name: 'Company name to company linkedin',
        input: ['company name'],
        output: 'company linkedin url',
        mode: 'single',
        service_code: 'Linkedin',
        video_link: 'https://www.youtube.com/embed/Cw8p1Hy3jnw',
        tag: '',
        tag_color: '',
        icon: '',
        available_integrations: [],
        integration_type: null
    },
    {
        id: 4,
        service_name: 'Company email to company name',
        input: ['company email'],
        output: 'company name',
        mode: 'single',
        service_code: 'ETC',
        video_link: 'https://www.youtube.com/embed/NsMZbe-QO4U',
        tag: '',
        tag_color: '',
        icon: '',
        available_integrations: [],
        integration_type: 'company'
    },
    {
        id: 5,
        service_name: 'Domain to company name',
        input: ['company domain'],
        output: 'company name',
        mode: 'single',
        service_code: 'DTC',
        video_link: 'https://www.youtube.com/embed/4_Vh-sFVhDY',
        tag: '',
        tag_color: '',
        icon: '',
        available_integrations: ['hubspot', 'zoho', 'salesforce'],
        integration_type: 'company'
    },
    {
        id: 6,
        service_name: 'contact name to LinkedIn',
        input: ['first name', 'last name', 'company name'],
        output: 'job title, linkedin url',
        mode: 'single',
        service_code: 'ConE',
        video_link: 'https://www.youtube.com/embed/kkc6eYvU3nE',
        tag: 'new',
        tag_color: 'success',
        icon: '',
        available_integrations: [],
        integration_type: 'contact'
    },
    {
        id: 7,
        service_name: "Company name to employees' LinkedIn",
        input: ['company name'],
        output: "list of employees' linkedin URLs",
        mode: 'single',
        service_code: 'Linkedin_P',
        video_link: 'https://www.youtube.com/embed/PEkr7Ic6AP8',
        tag: '',
        tag_color: '',
        icon: '',
        available_integrations: [],
        integration_type: null
    },
    {
        id: 8,
        service_name: 'Domain to company linkedin',
        input: ['company domain'],
        output: 'company linkedin url',
        mode: 'single',
        service_code: 'DTL',
        video_link: 'https://www.youtube.com/embed/pcz0kq-kfVg',
        tag: 'new',
        tag_color: 'success',
        icon: '',
        available_integrations: [],
        integration_type: null
    },
    {
        id: 9,
        service_name: 'Company LinkedIn to Domain',
        input: ['company linkedin url'],
        output: 'company domain',
        mode: 'single',
        service_code: 'LTD',
        video_link: 'https://www.youtube.com/embed/hP3EvjwEq9c',
        tag: 'new',
        tag_color: 'success',
        icon: '',
        available_integrations: [],
        integration_type: null
    },
    {
        id: 10,
        service_name: 'Contact email to contact LinkedIn',
        input: ['contact email'],
        output: 'contact linkedin url',
        mode: 'single',
        service_code: 'ETL',
        video_link: 'https://www.youtube.com/embed/ArZ5kYfDLeY',
        tag: 'new',
        tag_color: 'success',
        icon: '',
        available_integrations: [],
        integration_type: null
    },
    {
        id: 11,
        service_name: 'Contact LinkedIn to info',
        input: ['contact linkedin url'],
        output: 'first name, last name, job title, company name, contact work email',
        mode: 'single',
        service_code: 'LTI',
        video_link: 'https://www.youtube.com/embed/Y9oDMlPfll0',
        tag: 'new',
        tag_color: 'success',
        icon: '',
        available_integrations: [],
        integration_type: null
    },
    {
        id: 12,
        service_name: 'Email verifier',
        input: ['email'],
        output: 'deliverability status',
        mode: 'single',
        service_code: 'Email_Verify',
        video_link: 'https://www.youtube.com/embed/xu4BfV4V1xg',
        tag: 'free',
        tag_color: 'info',
        icon: '',
        available_integrations: [],
        integration_type: null
    },
    {
        id: 14,
        service_name: 'Company email finder',
        input: ['company domain'],
        output: 'first name, last name, company name, company email, type, accuracy',
        mode: 'single',
        service_code: 'DTE',
        video_link: 'https://www.youtube.com/embed/RXD15CJgzqU',
        tag: 'new',
        tag_color: 'success',
        icon: '',
        available_integrations: ['hubspot', 'zoho', 'salesforce'],
        integration_type: 'both'
    },
    {
        id: 15,
        service_name: 'Person email finder',
        input: ['first name', 'last name', 'company name/company domain'],
        output: 'contact email',
        mode: 'single',
        service_code: 'PITE',
        video_link: 'https://www.youtube.com/embed/fV-g_ma7VUM',
        tag: 'new',
        tag_color: 'success',
        icon: '',
        available_integrations: ['hubspot', 'zoho', 'salesforce'],
        integration_type: 'contact'
    },
    {
        id: 16,
        service_name: 'Company phone finder',
        input: ['company domain'],
        output: 'company phone',
        mode: 'single',
        service_code: 'DTP',
        video_link: 'https://www.youtube.com/embed/8xQe5UqNPwk',
        tag: 'new',
        tag_color: 'success',
        icon: '',
        available_integrations: [],
        integration_type: 'company'
    },
    {
        id: 17,
        service_name: 'Person phone finder',
        input: ['first name', 'last name', 'country'],
        output: 'person mobile phone',
        mode: 'single',
        service_code: 'ITM',
        video_link: 'https://www.youtube.com/embed/mc5TTHKtbpk',
        tag: 'new',
        tag_color: 'success',
        icon: '',
        available_integrations: [],
        integration_type: null
    },
    {
        id: 18,
        service_name: 'Phone number to person name',
        input: ['person phone number'],
        output: 'first name, last name, country',
        mode: 'single',
        service_code: 'MTI',
        video_link: 'https://www.youtube.com/embed/icGi-M67U3g',
        tag: 'new',
        tag_color: 'success',
        icon: '',
        available_integrations: ['hubspot', 'zoho', 'salesforce'],
        integration_type: 'contact'
    },
    {
        id: 19,
        service_name: 'Instagram to company domain',
        input: ['company Instagram URL'],
        output: 'company domain',
        mode: 'single',
        service_code: 'Instagram_TD',
        video_link: 'https://www.youtube.com/embed/gFq5Uv7N1GY',
        tag: 'new',
        tag_color: 'success',
        icon: '',
        available_integrations: [],
        integration_type: null
    },
    {
        id: 20,
        service_name: 'YouTube to company domain',
        input: ['company YouTube URL'],
        output: 'company domain',
        mode: 'single',
        service_code: 'Youtube_TD',
        video_link: 'https://www.youtube.com/embed/CsLh_fwpq38',
        tag: 'new',
        tag_color: 'success',
        icon: '',
        available_integrations: [],
        integration_type: null
    },
    {
        id: 21,
        service_name: 'Twitter to company domain',
        input: ['company Twitter URL'],
        output: 'company domain',
        mode: 'single',
        service_code: 'Twitter_TD',
        video_link: 'https://www.youtube.com/embed/8B5YRGkuz7I',
        tag: 'new',
        tag_color: 'success',
        icon: '',
        available_integrations: [],
        integration_type: null
    },
    {
        id: 22,
        service_name: 'Facebook to company domain',
        input: ['company Facebook URL'],
        output: 'company domain',
        mode: 'single',
        service_code: 'Facebook_TD',
        video_link: 'https://www.youtube.com/embed/9xidHVvL6JY',
        tag: 'new',
        tag_color: 'success',
        icon: '',
        available_integrations: [],
        integration_type: null
    },
    {
        id: 23,
        service_name: 'Domain to IP',
        input: ['company domain'],
        output: 'IP',
        mode: 'single',
        service_code: 'DTIP',
        video_link: 'https://www.youtube.com/embed/WcheiEcc5gs',
        tag: 'new',
        tag_color: 'success',
        icon: '',
        available_integrations: [],
        integration_type: null
    },
    {
        id: 24,
        service_name: 'Domain to page rank',
        input: ['company domain'],
        output: 'page rank',
        mode: 'single',
        service_code: 'DTPR',
        video_link: 'https://www.youtube.com/embed/1joxT94xMM8',
        tag: 'new',
        tag_color: 'success',
        icon: '',
        available_integrations: [],
        integration_type: null
    },
    {
        id: 25,
        service_name: 'contact email to name',
        input: ['contact email'],
        output: 'first name, last name',
        mode: 'single',
        service_code: 'ETN',
        video_link: 'https://www.youtube.com/embed/AEQ8-NnNxtw',
        tag: 'new',
        tag_color: 'success',
        icon: '',
        available_integrations: ['hubspot', 'zoho', 'salesforce'],
        integration_type: 'contact'
    },
    {
        id: 26,
        service_name: 'company name to phone',
        input: ['company name'],
        output: 'company phone',
        mode: 'single',
        service_code: 'NTP',
        video_link: 'https://www.youtube.com/embed/ilZ_tXT0Sfc',
        tag: 'new',
        tag_color: 'success',
        icon: '',
        available_integrations: [],
        integration_type: 'company'
    }
]

export const enrichmentServices = [
    {
        name: 'Name to Domain',
        code: 'CUF',
        credit_usage: 1,
        outputs: ['website_cufinder'],
        description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    },
    {
        name: 'Enrich Company',
        code: 'ENC',
        credit_usage: 3,
        outputs: [
            'Name_cufinder',
            'Website_cufinder',
            'Employee_Count_cufinder',
            'Company_Size_cufinder',
            'Company_Industry_cufinder',
            'Company_Description_cufinder',
            'Company_LinkedIn_URL_cufinder',
            'Company_Type_cufinder',
            'Company_Domain_cufinder',
            'Company_Country_cufinder',
            'Company_State_cufinder',
            'Company_City_cufinder',
            'Company_Address_cufinder',
            'Company_Founded_Year_cufinder',
            'Company_Logo_URL_cufinder',
            'Company_Followers_Count_cufinder'
        ],
        description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    }
]
