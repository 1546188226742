// ** mui imports
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

// ** type imports
import { Settings } from 'src/@core/context/settingsContext'

// ** custom components imports
import UserDropdown from 'src/@core/layouts/components/shared-components/UserDropdown'
import Icon from 'src/@core/components/icon'
import Button from 'src/components/Button'

interface Props {
    hidden: boolean
    settings: Settings
    toggleNavVisibility: () => void
    saveSettings: (values: Partial<Settings>) => void
}

const AppBarContent = (props: Props) => {
    // ** Props
    const { hidden, settings, toggleNavVisibility } = props

    return (
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box className='actions-left' sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
                {hidden ? (
                    <>
                        <IconButton color='inherit' sx={{ ml: -2.75 }} onClick={toggleNavVisibility}>
                            <Icon icon='mdi:menu' />
                        </IconButton>
                    </>
                ) : null}
            </Box>
            <Box className='actions-right' sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                <Button
                    color='secondary'
                    href='/lists'
                    startIcon={<Icon icon='mdi:clipboard-list-outline' />}
                    size='small'
                    sx={{ minWidth: '0!important', width: '6rem' }}
                >
                    lists
                </Button>
                <Button
                    id='appbar-upgrade-btn'
                    href='/plans'
                    color='success'
                    startIcon={<Icon icon='carbon:upgrade' />}
                    size='small'
                    sx={{ minWidth: '0!important', width: '6rem' }}
                >
                    upgrade
                </Button>
                <UserDropdown settings={settings} />
            </Box>
        </Box>
    )
}

export default AppBarContent
