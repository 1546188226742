// ** react imports
import { FC } from 'react'

// ** react router imports
import { RouterProvider } from 'react-router-dom'

// ** routes imports
import mainRoutes from './routes/main.routes'

// ** third party imports
import { HelmetProvider } from 'react-helmet-async'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false
        },
        mutations: {
            retry: false
        }
    }
})

const App: FC = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <HelmetProvider>
                <RouterProvider router={mainRoutes} future={{ v7_startTransition: true }} />
            </HelmetProvider>
        </QueryClientProvider>
    )
}

export default App
