import { useState } from 'react'
import Box, { type BoxProps } from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Textfield from 'src/components/shared/Textfield'
import Button from 'src/components/shared/Button'
import Icon from 'src/components/shared/Icon'
import EnrichServicesList from 'src/components/new-enrichment/enrich/ServicesList'
import EnrichServiceIO from 'src/components/new-enrichment/enrich/ServiceIO'
import services from 'src/data/enrichment/services'
import type { Type, Service, ServiceCode } from 'src/types/enrichment'

type Props = Omit<BoxProps, 'onSubmit'> & {
    bulkId: number
    closable?: boolean
    onClose?: () => void
    onSubmit?: (type: ServiceCode) => void
}

const tabs: { value: Type; text: string }[] = [
    {
        value: 'company',
        text: 'Company'
    },
    {
        value: 'contact',
        text: 'People'
    }
]

export default function AddAction({ bulkId, closable = false, onClose, onSubmit, ...rest }: Props) {
    const [step, setStep] = useState(1)
    const [type, setType] = useState<null | Type>(null)
    const [service, setService] = useState<null | Service>(null)
    const [search, setSearch] = useState('')
    const [tab, setTab] = useState<Type>('company')
    const companiesServicesFiltered = services.filter(
        (service) => service.type === 'company' && service.title.toLowerCase().includes(search.toLowerCase())
    )
    const peopleServicesFiltered = services.filter(
        (service) => service.type === 'contact' && service.title.toLowerCase().includes(search.toLowerCase())
    )
    const totalSteps = 2
    const isFirstStep = step === 1
    const isLastStep = step === totalSteps
    const onServiceChange = ({ type, service }: { type: Type; service: Service }) => {
        setType(type)
        setService(service)
        nextStep()
    }
    const prevStep = () => {
        setStep((old) => old - 1)
    }
    const nextStep = () => {
        setStep((old) => old + 1)
    }

    return (
        <Box display='flex' flexDirection='column' height={1} {...rest}>
            <Stack
                flexShrink={0}
                bgcolor='newGrey.light4'
                p={2.5}
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                gap={4}
                borderBottom={1}
                borderColor='newNeutral.light5'
            >
                <Stack direction='row' alignItems='center' spacing={4}>
                    {!isFirstStep && (
                        <Button variant='text' onClick={prevStep} sx={{ p: 1, minWidth: 0 }}>
                            <Icon icon='solar:arrow-left-linear' color='newNeutral' size='md' />
                        </Button>
                    )}
                    <Typography component='h4' variant='newLabelMd' color='newNeutral'>
                        {step === 1 ? 'Add Enrichment' : service?.title}
                    </Typography>
                </Stack>
                {closable && (
                    <Button variant='text' size='sm' onClick={onClose} sx={{ p: 1, minWidth: 0 }}>
                        <Icon icon='mdi:close' color='newNeutral' size='md' />
                    </Button>
                )}
            </Stack>
            <Box flexGrow={1} overflow='auto'>
                {step === 1 && (
                    <Box p={6}>
                        {/* <Textfield
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            borderColor='newNeutral.light4'
                            variant='outlined'
                            color='newEnrichment'
                            size='sm'
                            placeholder='Search'
                            prependInnerIcon='solar:magnifer-outline'
                            sx={{ mb: 6 }}
                        /> */}
                        <Stack width={1} direction='row' bgcolor='newGrey.light4' p={0.5} borderRadius={1}>
                            {tabs.map((t) => {
                                const isActive = t.value === tab
                                return (
                                    <Button
                                        key={t.value}
                                        variant={isActive ? 'contained' : 'text'}
                                        size='xs'
                                        sx={{
                                            width: '50%',
                                            py: 2,
                                            bgcolor: isActive ? 'white' : 'transparent',
                                            color: isActive ? 'newNeutral.main' : 'newNeutral.light2',
                                            borderWidth: isActive ? 1 : 0,
                                            borderColor: 'newNeutral.light5'
                                        }}
                                        className={isActive ? 'shadow-1' : ''}
                                        onClick={() => setTab(t.value)}
                                    >
                                        {t.text}
                                    </Button>
                                )
                            })}
                        </Stack>
                        <Box mt={8}>
                            {!!companiesServicesFiltered.length && tab === 'company' && (
                                <EnrichServicesList
                                    title='Company'
                                    icon='solar:buildings-3-bold-duotone'
                                    iconColor='newPastelPurple'
                                    services={companiesServicesFiltered}
                                    onChange={(newService) => onServiceChange({ type: 'company', service: newService })}
                                    mt={6}
                                />
                            )}
                            {!!peopleServicesFiltered.length && tab === 'contact' && (
                                <EnrichServicesList
                                    title='People'
                                    icon='ph:users-three-duotone'
                                    iconColor='newPastelRed'
                                    services={peopleServicesFiltered}
                                    onChange={(newService) => onServiceChange({ type: 'contact', service: newService })}
                                    mt={10}
                                />
                            )}
                        </Box>
                    </Box>
                )}
                {!!(step === 2 && service) && (
                    <EnrichServiceIO bulkId={bulkId} {...service} onSubmit={onSubmit} px={0} pt={6} pb={0} />
                )}
            </Box>
        </Box>
    )
}
