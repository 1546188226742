import { useState, useEffect, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery, useMutation } from '@tanstack/react-query'
import Box, { type BoxProps } from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Drawer from '@mui/material/Drawer'
import Checkbox from '@mui/material/Checkbox'
import {
    useGridApiRef,
    // type GridApiPro,
    type GridColDef,
    type GridFilterModel,
    // type GridPaginationModel,
    type GridRowSelectionModel
} from '@mui/x-data-grid-pro'
// import DataTable from 'src/components/shared/DataTable'
import BaseMuiDataGrid from 'src/components/shared/BaseMuiDataGrid'
import Textfield from 'src/components/shared/Textfield'
import Button from 'src/components/shared/Button'
import Icon from 'src/components/shared/Icon'
import CircularProgress from 'src/components/shared/CircularProgress'
import AddAction from 'src/components/new-enrichment/enrich/AddAction'
import DeleteDialog from 'src/components/shared/DeleteDialog'
import TickLoader from 'src/components/shared/TickLoader'
import { getBulk, deleteBulkRows } from 'src/services/new-enrichment'
import {
    connect as socketConnect,
    disconnect as socketDisconnect,
    emitGetBulk,
    onBulkUpdate
} from 'src/services/socket/enrichment'
import { generateExcel, downloadFile } from 'src/utils/file'
import { type Bulk, type ServiceCode, Status } from 'src/types/enrichment'

type Row = {
    _id: string
    rowNum: number
    [key: string]: unknown
}
type Props = BoxProps & { bulkId: number }

const intl = Intl.NumberFormat('en-US')

export default function EnrichTable({ bulkId, ...rest }: Props) {
    const navigate = useNavigate()
    const tableRef = useGridApiRef()
    const [filters, setFilters] = useState<GridFilterModel>({ items: [], quickFilterValues: [''] })
    const [bulk, setBulk] = useState<null | Bulk>(null)
    // const [pagination, setPagination] = useState<GridPaginationModel>({ page: 1, pageSize: 3 })
    const [selections, setSelections] = useState<GridRowSelectionModel>([])
    const [bulkLoading, setBulkLoading] = useState(false) //base bulk file loading
    const [enrichLoading, setEnrichLoading] = useState(false) //when we try to enrich bulk file
    const [processingColumns, setProcessingColumns] = useState<string[]>([])
    const [downloadLoading, setDownloadLoading] = useState(false)
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)
    const [showEnrichForm, setShowEnrichForm] = useState(false)
    const currColsCount = useRef(0) //hold latest counts of columns ... useful for times we want to scroll table to the right
    // const {
    //     isFetching: bulkLoading,
    //     data: bulk,
    //     refetch: getBulkReq
    // } = useQuery({
    //     initialData: null,
    //     queryKey: ['get-bulk', bulkId],
    //     refetchInterval: 5000,
    //     queryFn: async () => {
    //         const bulk = await getBulk({ id: bulkId })
    //         return bulk
    //     }
    // })
    const headers = bulk ? bulk.cellsDataNormalize[0] : []
    const hasSelections = !!selections.length
    const totalCounts = bulk?.cellsData.length || 0
    const rows = useMemo<Row[]>(() => {
        return bulk?.cellsData.map((data, i) => ({ ...data, rowNum: i + 1 })) || []
    }, [bulk])
    const columns = useMemo<GridColDef<Row>[]>(() => {
        const cols: GridColDef[] = []
        const checkboxColumn: GridColDef<Row> = {
            field: 'index',
            headerName: '#',
            width: 60,
            sortable: false,
            disableColumnMenu: true,
            headerClassName: 'checkbox-header-cell',
            cellClassName: 'checkbox-content-cell',
            renderHeader: () => {
                const isAllChecked = selections.length === rows.length
                const isIndeterminate = selections.length > 0 && selections.length < rows.length
                return (
                    <Checkbox
                        checked={isAllChecked}
                        indeterminate={isIndeterminate}
                        size='small'
                        color='newNeutral'
                        onChange={(e) => {
                            const checked = e.target.checked
                            setSelections(checked ? rows.map((r) => r._id) : [])
                        }}
                        sx={{
                            color: 'newNeutral.light4'
                        }}
                    />
                )
            },
            renderCell: ({ id, api }) => {
                const [hovered, setHovered] = useState(false)
                const isChecked = selections.includes(id)
                const showCheckbox = hovered || isChecked
                return (
                    <Stack
                        width={1}
                        height={1}
                        px={showCheckbox ? 0 : 2.5}
                        justifyContent='center'
                        alignItems='start'
                        onMouseEnter={() => setHovered(true)}
                        onMouseLeave={() => setHovered(false)}
                    >
                        {showCheckbox ? (
                            <Checkbox
                                checked={isChecked}
                                value={id}
                                size='small'
                                color='newNeutral'
                                onChange={(e) => {
                                    const checked = e.target.checked
                                    const value = e.target.value
                                    setSelections((old) => (checked ? [...old, value] : old.filter((o) => o !== value)))
                                }}
                                sx={{
                                    color: 'newNeutral.light4'
                                }}
                            />
                        ) : (
                            <Typography component='span' variant='newLabelSm' color='newNeutral.light2'>
                                {api.getRowIndexRelativeToVisibleRows(id) + 1}
                            </Typography>
                        )}
                    </Stack>
                )
            }
        }
        headers.forEach((header) => {
            const headerStr = `${header}`
            const isProcessing =
                enrichLoading && Array.isArray(processingColumns) && processingColumns.includes(headerStr)
            cols.push({
                field: headerStr,
                headerName: headerStr,
                width: 250,
                headerClassName: isProcessing ? 'loader-header' : '', //will be apply on .MuiDataGrid-columnHeader
                cellClassName: isProcessing ? 'loader-cell' : '', //will be apply on .MuiDataGrid-cell
                renderHeader: ({ colDef: { headerName } }) => {
                    return (
                        <Stack direction='row' justifyContent='space-between' alignItems='center' gap={2}>
                            <Typography variant='newLabelMd' color='newNeutral' textTransform='lowercase'>
                                {headerName}
                            </Typography>
                            {isProcessing && <TickLoader size={10} color='newEnrichment' width={1} height={6} />}
                        </Stack>
                    )
                },
                renderCell: ({ value }) => <p className='line-clamp-1'>{value}</p> //we don't use <Typography /> so we keep default table cell text styles
            })
        })
        return [checkboxColumn, ...cols]
    }, [rows, headers, selections])
    const scrollTable = ({ rowIndex, colIndex }: { rowIndex?: number; colIndex?: number }) => {
        //we define separated custom component for it and we call it only in locations that we need it
        const table = tableRef.current
        if (table) {
            setTimeout(() => {
                //use setTimeout to make sure table content is ready and we have new data inside table
                try {
                    table.scrollToIndexes({ rowIndex, colIndex })
                } catch (err) {}
            }, 1000)
        }
    }
    const emitBulk = async () => {
        setBulkLoading(true)
        try {
            emitGetBulk(
                {
                    id: bulkId
                },
                (bulk) => {
                    if (!bulk.isExist) throw new Error('Not Found !')
                    else if (bulk.id !== bulkId) {
                        setBulk(null)
                        setBulkLoading(false)
                    } else {
                        setBulk(bulk)
                        setBulkLoading(false)
                        setProcessingColumns(bulk.processingColumns)
                        setEnrichLoading(bulk.status === Status.PENDING)
                    }
                }
            )
        } catch (err) {
            navigate('*')
        }
    }
    const { isPending: deleteLoading, mutateAsync: deleteRows } = useMutation({
        mutationFn: async () => {
            await deleteBulkRows({ bulkId, rowIds: selections as string[] })
        },
        onSuccess: async () => {
            if (selections.length === bulk?.rowCount) {
                //if we delete all rows:
                navigate('/enrichment/bulks')
            } else {
                // const { page, pageSize } = pagination
                setSelections([])
                setShowDeleteDialog(false)
                // if (totalCounts - selections.length <= (page - 1) * pageSize) {
                //     setPagination((old) => ({ ...old, page: old.page - 1 }))
                // }
                emitBulk()
            }
        }
    })
    const downloadFileInfos = async () => {
        setDownloadLoading(true)
        const blob = await generateExcel({
            data: bulk?.cellsDataNormalize || [],
            colsWidth: headers.map((head) => (`${head}`.includes('description') ? 80 : 20))
        })
        downloadFile({ blob, filename: bulk!.name, extension: 'xlsx' })
        setDownloadLoading(false)
    }
    useEffect(() => {
        currColsCount.current = columns.length
    }, [columns.length])
    useEffect(() => {
        // only on first render connect to socket and disconnect when we unmount component
        socketConnect(() => {
            emitBulk() //emit to get initial data
            try {
                //list to socket events
                onBulkUpdate((newBulk) => {
                    //we don't use setLoading(true) here and we should set it before any action that can trigger socket .on methods ... here we use it inside onSubmit of <AddAction />
                    const newColsCount = newBulk.cellsDataNormalize[0].length
                    if (!newBulk.isExist) throw new Error('Not Found !')
                    else if (newBulk.id !== bulkId) {
                        setBulk(null)
                        setEnrichLoading(false)
                    } else {
                        setBulk(newBulk)
                        setProcessingColumns(newBulk.processingColumns)
                        setEnrichLoading(newBulk.status === Status.PENDING)
                        if (newBulk.code !== 'FCL' && currColsCount.current !== newColsCount) {
                            //only scroll table if we update columns
                            scrollTable({ colIndex: newBulk.cellsDataNormalize[0].length }) //columns will be generated from first cell of bulk.cellsDataNormalize so bulk.cellsDataNormalize[0].length will be last cell
                        }
                    }
                })
            } catch (err) {
                navigate('*')
            }
        })
        return () => {
            socketDisconnect()
        }
    }, [])

    return (
        <Box {...rest}>
            <Box bgcolor='white' px={0} pt={3} pb={0} border={1} borderRadius={1} borderColor='newNeutral.light5'>
                <Box px={3}>
                    <Stack direction='row' justifyContent='space-between' alignItems='center' flexWrap='wrap' gap={3}>
                        <Stack direction='row' alignItems='center' flexWrap='wrap' gap={2}>
                            {enrichLoading && (
                                <Stack direction='row' alignItems='center' gap={2}>
                                    <TickLoader size={12} width={1} height={6} color='newNeutral' />
                                    <Typography variant='newLabelMd' color='newNeutral'>
                                        Updating Table...
                                    </Typography>
                                </Stack>
                            )}
                            {!enrichLoading && (
                                <Typography variant='newLabelMd' color='newNeutral'>
                                    {!hasSelections
                                        ? `${intl.format(totalCounts)} rows total`
                                        : `${selections.length} ${selections.length > 1 ? 'Items' : 'Item'} Selected`}
                                </Typography>
                            )}
                        </Stack>
                        <Stack direction='row' alignItems='center' flexWrap='wrap' gap={2}>
                            {!hasSelections && (
                                <>
                                    <Textfield
                                        variant='outlined'
                                        size='xs'
                                        color='newNeutral'
                                        borderColor='newNeutral.light4'
                                        value={filters.quickFilterValues}
                                        onChange={(e) =>
                                            setFilters((old) => ({ ...old, quickFilterValues: [e.target.value || ''] }))
                                        }
                                        prependInnerIcon='tdesign:search'
                                        placeholder='Search'
                                        bgcolor='white'
                                        fullWidth={false}
                                        sx={{ width: 232 }}
                                    />
                                    <Button
                                        disabled={!bulk}
                                        // loading={downloadLoading}
                                        loadingColor='newPrimary'
                                        variant='text'
                                        size='xs'
                                        sx={{ bgcolor: 'white', minWidth: 0, py: 2 }}
                                        onClick={() => downloadFileInfos()}
                                        className='shadow-2'
                                    >
                                        <Icon icon='tdesign:download' color='newNeutral' size='md' />
                                    </Button>
                                    <Button
                                        variant='contained'
                                        color='newNeutral'
                                        size='xs'
                                        sx={{ px: 5, color: 'white' }}
                                        onClick={() => setShowEnrichForm(true)}
                                    >
                                        <Icon icon='subway:power' color='white' size='sm' className='mr-2' />
                                        Enrich
                                    </Button>
                                </>
                            )}
                            {hasSelections && (
                                <Button
                                    loading={downloadLoading}
                                    variant='outlined'
                                    color='newNeutral'
                                    size='xs'
                                    sx={{ borderColor: 'newNeutral.light5', boxShadow: 1, '&:hover': { boxShadow: 1 } }}
                                    onClick={() => setShowDeleteDialog(true)}
                                >
                                    <Icon icon='mdi:delete-outline' color='newError' size='sm' className='mr-2' />
                                    Delete
                                </Button>
                            )}
                        </Stack>
                    </Stack>
                </Box>
                <Box mt={3} display='flex' flexDirection='column' height='calc(100vh - 145px)'>
                    <BaseMuiDataGrid
                        apiRef={tableRef}
                        loading={bulkLoading}
                        color='newNeutral'
                        getRowId={(row) => row._id}
                        columns={columns}
                        rows={rows}
                        columnHeaderHeight={36}
                        getRowHeight={(row) => 36}
                        showCellVerticalBorder
                        showColumnVerticalBorder
                        filterMode='client'
                        filterModel={filters}
                        onFilterModelChange={(newVal) => setFilters(newVal)}
                        paginationMode='client'
                        pagination={false}
                        checkboxSelection={false}
                        disableRowSelectionOnClick
                        rowSelectionModel={selections}
                        onRowSelectionModelChange={(newVal) => setSelections(newVal)}
                        hideFooter
                        slots={{
                            footer: (props) => (
                                <Box {...props} p={3} borderTop={1} borderColor='newNeutral.light5'>
                                    <Stack
                                        direction='row'
                                        justifyContent='space-between'
                                        alignItems='center'
                                        gap={2}
                                        flexWrap='wrap'
                                    >
                                        <Stack direction='row' alignItems='center' gap={2} flexWrap='wrap'>
                                            {enrichLoading && (
                                                <Stack direction='row' alignItems='center' gap={2}>
                                                    <CircularProgress variant='indeterminate' size={15} thickness={2} />
                                                    <Typography variant='newLabelMd' color='newNeutral.light3'>
                                                        Updating Cells ...
                                                    </Typography>
                                                </Stack>
                                            )}
                                        </Stack>
                                        <Typography component='p' variant='newBodySm' color='newNeutral'>
                                            Total Row: {intl.format(rows.length)}
                                        </Typography>
                                    </Stack>
                                </Box>
                            )
                        }}
                        slotProps={{
                            baseCheckbox: {
                                color: 'newNeutral',
                                size: 'small'
                            }
                        }}
                        sx={{
                            borderRight: 'none',
                            borderLeft: 'none',
                            borderBottom: 'none',
                            '& .MuiDataGrid-columnHeaders': {
                                minHeight: 'auto !important',
                                maxHeight: 'auto !important',
                                '& .MuiDataGrid-columnHeader': {
                                    bgcolor: 'newSurface.light3',
                                    p: 0
                                }
                            },
                            '& .MuiDataGrid-virtualScrollerContent': {
                                '& .MuiDataGrid-row': {
                                    typography: 'newBodySm',
                                    color: 'newNeutral.light2'
                                }
                            },
                            '& .MuiDataGrid-columnHeader.checkbox-header-cell': {
                                p: 0
                            },
                            '& .MuiDataGrid-cell.checkbox-content-cell': {
                                p: 0
                            },
                            '& .loader-header,.loader-cell': {
                                bgcolor: 'newPrimary.light5'
                            }
                            // '& .MuiCheckbox-root:not(.Mui-checked) svg': {
                            //     color: 'newNeutral.light5'
                            // }
                        }}
                    />
                </Box>
            </Box>
            <DeleteDialog
                open={showDeleteDialog}
                onOpenChange={(val) => setShowDeleteDialog(val)}
                title='Delete Rows'
                loading={deleteLoading}
                btnColor='newNeutral'
                onDelete={() => deleteRows()}
            >
                Are you sure that you want to delete selected Rows ?
            </DeleteDialog>
            <Drawer
                anchor='right'
                open={showEnrichForm}
                onClose={() => setShowEnrichForm(false)}
                PaperProps={{
                    sx: {
                        width: 540,
                        maxWidth: '90vw',
                        borderRadius: 1,
                        overflow: 'hidden',
                        height: 'calc(100vh - 1.5rem)',
                        top: '.75rem',
                        right: '.75rem'
                    }
                }}
            >
                <AddAction
                    bulkId={bulkId}
                    closable
                    onClose={() => setShowEnrichForm(false)}
                    onSubmit={(code) => {
                        setEnrichLoading(true)
                        setShowEnrichForm(false)
                        if (code === 'FCL') navigate('/enrichment/bulks')
                    }}
                />
            </Drawer>
        </Box>
    )
}
