export const countriesMap = new Map<string, string>([
    ['us', 'United States'],
    ['fr', 'France'],
    ['de', 'Germany'],
    ['dk', 'Denmark'],
    ['gr', 'Greece'],
    ['il', 'Israel'],
    ['it', 'Italy'],
    ['mx', 'Mexico'],
    ['nl', 'Netherlands'],
    ['no', 'Norway'],
    ['ru', 'Russian Federation'],
    ['es', 'Spain'],
    ['se', 'Sweden'],
    ['gb', 'United Kingdom (Great Britain)'],
    ['af', 'Afghanistan'],
    ['al', 'Albania'],
    ['dz', 'Algeria'],
    ['as', 'American Samoa'],
    ['ad', 'Andorra'],
    ['ao', 'Angola'],
    ['ai', 'Anguilla'],
    ['aq', 'Antarctica'],
    ['ag', 'Antigua & Barbuda'],
    ['ar', 'Argentina'],
    ['am', 'Armenia'],
    ['aw', 'Aruba'],
    ['au', 'Australia'],
    ['at', 'Austria'],
    ['az', 'Azerbaijan'],
    ['bs', 'Bahama'],
    ['bh', 'Bahrain'],
    ['bd', 'Bangladesh'],
    ['bb', 'Barbados'],
    ['by', 'Belarus'],
    ['be', 'Belgium'],
    ['bz', 'Belize'],
    ['bj', 'Benin'],
    ['bm', 'Bermuda'],
    ['bt', 'Bhutan'],
    ['bo', 'Bolivia'],
    ['ba', 'Bosnia and Herzegovina'],
    ['bw', 'Botswana'],
    ['bv', 'Bouvet Island'],
    ['br', 'Brazil'],
    ['io', 'British Indian Ocean Territory'],
    ['vg', 'British Virgin Islands'],
    ['bn', 'Brunei Darussalam'],
    ['bg', 'Bulgaria'],
    ['bf', 'Burkina Faso'],
    ['bi', 'Burundi'],
    ['kh', 'Cambodia'],
    ['cm', 'Cameroon'],
    ['ca', 'Canada'],
    ['cv', 'Cape Verde'],
    ['ky', 'Cayman Islands'],
    ['cf', 'Central African Republic'],
    ['td', 'Chad'],
    ['cl', 'Chile'],
    ['cn', 'China'],
    ['cx', 'Christmas Island'],
    ['cc', 'Cocos (Keeling) Islands'],
    ['co', 'Colombia'],
    ['km', 'Comoros'],
    ['cg', 'Congo'],
    ['ck', 'Cook Islands'],
    ['cr', 'Costa Rica'],
    ['hr', 'Croatia'],
    ['cu', 'Cuba'],
    ['cy', 'Cyprus'],
    ['cz', 'Czech Republic'],
    ['ci', 'Cote Divoire (Ivory Coast)'],
    ['dj', 'Djibouti'],
    ['dm', 'Dominica'],
    ['do', 'Dominican Republic'],
    ['tl', 'East Timor'],
    ['ec', 'Ecuador'],
    ['eg', 'Egypt'],
    ['sv', 'El Salvador'],
    ['gq', 'Equatorial Guinea'],
    ['er', 'Eritrea'],
    ['ee', 'Estonia'],
    ['et', 'Ethiopia'],
    ['fk', 'Falkland Islands (Malvinas)'],
    ['fo', 'Faroe Islands'],
    ['fj', 'Fiji'],
    ['fi', 'Finland'],
    ['gf', 'French Guiana'],
    ['pf', 'French Polynesia'],
    ['tf', 'French Southern Territories'],
    ['ga', 'Gabon'],
    ['gm', 'Gambia'],
    ['ge', 'Georgia'],
    ['gh', 'Ghana'],
    ['gi', 'Gibraltar'],
    ['gl', 'Greenland'],
    ['gd', 'Grenada'],
    ['gp', 'Guadeloupe'],
    ['gu', 'Guam'],
    ['gt', 'Guatemala'],
    ['gn', 'Guinea'],
    ['gw', 'Guinea Bissau'],
    ['gy', 'Guyana'],
    ['ht', 'Haiti'],
    ['hm', 'Heard & McDonald Islands'],
    ['hn', 'Honduras'],
    ['hk', 'Hong Kong'],
    ['hu', 'Hungary'],
    ['is', 'Iceland'],
    ['in', 'India'],
    ['id', 'Indonesia'],
    ['iq', 'Iraq'],
    ['ie', 'Ireland'],
    ['ir', 'Islamic Republic of Iran'],
    ['jm', 'Jamaica'],
    ['jp', 'Japan'],
    ['jo', 'Jordan'],
    ['kz', 'Kazakhstan'],
    ['ke', 'Kenya'],
    ['ki', 'Kiribati'],
    ['kp', 'North Korea'],
    ['kr', 'South korea'],
    ['kw', 'Kuwait'],
    ['kg', 'Kyrgyzstan'],
    ['la', 'Laos'],
    ['lv', 'Latvia'],
    ['lb', 'Lebanon'],
    ['ls', 'Lesotho'],
    ['lr', 'Liberia'],
    ['ly', 'Libya'],
    ['li', 'Liechtenstein'],
    ['lt', 'Lithuania'],
    ['lu', 'Luxembourg'],
    ['mo', 'Macau'],
    ['mg', 'Madagascar'],
    ['mw', 'Malawi'],
    ['my', 'Malaysia'],
    ['mv', 'Maldives'],
    ['ml', 'Mali'],
    ['mt', 'Malta'],
    ['mh', 'Marshall Islands'],
    ['mq', 'Martinique'],
    ['mr', 'Mauritania'],
    ['mu', 'Mauritius'],
    ['yt', 'Mayotte'],
    ['fm', 'Micronesia'],
    ['md', 'Moldova'],
    ['mc', 'Monaco'],
    ['mn', 'Mongolia'],
    ['ms', 'Montserrat'],
    ['ma', 'Morocco'],
    ['mz', 'Mozambique'],
    ['mm', 'Myanmar'],
    ['na', 'Namibia'],
    ['nr', 'Nauru'],
    ['np', 'Nepal'],
    ['nc', 'New Caledonia'],
    ['nz', 'New Zealand'],
    ['ni', 'Nicaragua'],
    ['ne', 'Niger'],
    ['ng', 'Nigeria'],
    ['nu', 'Niue'],
    ['nf', 'Norfolk Island'],
    ['mp', 'Northern Mariana Islands'],
    ['om', 'Oman'],
    ['pk', 'Pakistan'],
    ['pw', 'Palau'],
    ['pa', 'Panama'],
    ['pg', 'Papua New Guinea'],
    ['py', 'Paraguay'],
    ['pe', 'Peru'],
    ['ph', 'Philippines'],
    ['pn', 'Pitcairn'],
    ['pl', 'Poland'],
    ['pt', 'Portugal'],
    ['pr', 'Puerto Rico'],
    ['qa', 'Qatar'],
    ['ro', 'Romania'],
    ['rw', 'Rwanda'],
    ['re', 'Reunion'],
    ['lc', 'Saint Lucia'],
    ['ws', 'Samoa'],
    ['sm', 'San Marino'],
    ['st', 'Sao Tome & Principe'],
    ['sa', 'Saudi Arabia'],
    ['sn', 'Senegal'],
    ['sc', 'Seychelles'],
    ['sl', 'Sierra Leone'],
    ['sg', 'Singapore'],
    ['sk', 'Slovakia'],
    ['si', 'Slovenia'],
    ['sb', 'Solomon Islands'],
    ['so', 'Somalia'],
    ['za', 'South Africa'],
    ['gs', 'South Georgia and the South Sandwich Islands'],
    ['lk', 'Sri Lanka'],
    ['sh', 'St. Helena'],
    ['kn', 'St. Kitts and Nevis'],
    ['pm', 'St. Pierre & Miquelon'],
    ['vc', 'St. Vincent & the Grenadines'],
    ['sd', 'Sudan'],
    ['sr', 'Suriname'],
    ['sj', 'Svalbard & Jan Mayen Islands'],
    ['sz', 'Swaziland'],
    ['ch', 'Switzerland'],
    ['sy', 'Syrian Arab Republic'],
    ['tw', 'Taiwan, Province of China'],
    ['tj', 'Tajikistan'],
    ['tz', 'Tanzania, United Republic of'],
    ['th', 'Thailand'],
    ['tg', 'Togo'],
    ['tk', 'Tokelau'],
    ['to', 'Tonga'],
    ['tt', 'Trinidad & Tobago'],
    ['tn', 'Tunisia'],
    ['tr', 'Turkey'],
    ['tm', 'Turkmenistan'],
    ['tc', 'Turks & Caicos Islands'],
    ['tv', 'Tuvalu'],
    ['ug', 'Uganda'],
    ['ua', 'Ukraine'],
    ['ae', 'United Arab Emirates'],
    ['um', 'United States Minor Outlying Islands'],
    ['vi', 'United States Virgin Islands'],
    ['uy', 'Uruguay'],
    ['uz', 'Uzbekistan'],
    ['vu', 'Vanuatu'],
    ['va', 'Vatican City State (Holy See)'],
    ['ve', 'Venezuela'],
    ['vn', 'VietNam'],
    ['wf', 'Wallis & Futuna Islands'],
    ['eh', 'Western Sahara'],
    ['ye', 'Yemen'],
    ['cd', 'Zaire'],
    ['zm', 'Zambia'],
    ['zw', 'Zimbabwe']
])
export const getCountry = (code: string) => countriesMap.get(code)

//export const countriesArray = Array.from(countriesMap).map((country) => ({ code: country[0], name: country[1] }))
//for getter performance we don't create array version here and instead we generate it where we need it
