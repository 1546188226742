import { type InternalAxiosRequestConfig, type AxiosError } from 'axios'
import { toast } from 'src/utils'
import authConfigs from 'src/configs/auth'
import ApiService from 'src/services/api.service'
import useUser from 'src/store/user'

const hideToastResponseCodes = [
    ApiService.ResponseCode.UPGRADE_PLAN_REQUIRED_ERR,
    ApiService.ResponseCode.STRIPE_ACCOUNT_OLD_ERR
] //list if response codes that we don't want to show error toast for them

export const setAuthToken = (config: InternalAxiosRequestConfig) => {
    //set bearer token into Authorization header
    const token = localStorage.getItem(authConfigs.storageTokenKeyName)
    if (token) config.headers.Authorization = `Bearer ${token}`
}

type ResponseError = {
    status: 'ok' | 'nok'
    code?: ApiService.ResponseCode
    message?: string
    errors?: { [key: string]: string }[]
}
export const handleResponseError = (error: AxiosError<ResponseError>) => {
    const { status: httpStatus, code: httpCode, message: httpMessage, response } = error
    //above code,status is http code,status and its not same as response?.data?.status('ok|'nok') or response?.data?.code(server custom code)
    if (response?.data?.status === 'nok') {
        //instead of showing toast from axios interceptor we could show snackbar,alert,toast from inside each component that we wanted to handle http req error state
        const { code: resCode, errors: resErrors, message: resMsg } = response.data
        const resCodeType = resCode as ApiService.ResponseCode
        const resParsedErrors = resErrors?.map((err, i) => `${i + 1}- ${Object.values(err).join(', ')}`).join(`\n`) //get from response.data.errors
        const resCustomMsg =
            resCodeType === ApiService.ResponseCode.RATE_LIMIT_EXCEEDED_ERR
                ? resMsg
                : ApiService.getCodeMessage(resCodeType) //get from response.data.code
        const msg =
            resParsedErrors || resCustomMsg || resMsg || httpMessage || 'Error happens, Please try again later !'
        // toast({
        //     message: httpStatus === 422 ? `Please resolve these errors:\n${msg}` : `${msg}`,
        //     color: 'error'
        // })
        if (!hideToastResponseCodes.includes(resCodeType)) {
            toast({
                message: httpStatus === 422 ? `Please resolve these errors:\n${msg}` : `${msg}`,
                color: 'error'
            })
        }
    }
    if (httpStatus === 401) {
        // if status code is 401,403 logout(clear localStorage,store,...) and redirect to login else show error message to user(e.g for other server errors like validation,...)
        const store = useUser.getState()
        store.logout()
        const redirectUrl = `/auth/signin?returnUrl=${window.location.pathname}`
        window.location.replace(redirectUrl)
    }
}
