// ** react imports
import { Suspense, lazy } from 'react'

// ** components imports
import Spinner from 'src/@core/components/spinner'
import { CampaignProvider } from 'src/@core/context/campaignContext'

// ** screens imports
import DefaultScreen from 'src/pages/default-screen'

// ** third party imports
import { RouteObject } from 'react-router-dom'

// ** views imports
// credit usage
const CreditUsageScreen = lazy(() => import('src/pages/credits-usage'))

// prospect engine
const ContactSearchScreen = lazy(() => import('src/pages/prospect/contact/search'))
const ContactInfoScreen = lazy(() => import('src/pages/prospect/contact/info'))
const CompanySearchScreen = lazy(() => import('src/pages/prospect/company/search'))
const BulkSearchScreen = lazy(() => import('src/pages/prospect/bulk/search'))
const CompanyDetailsScreen = lazy(() => import('src/pages/prospect/company/[id]'))
const CorporationDetailsScreen = lazy(() => import('src/pages/prospect/corporation/[id]'))

// enrichment engine
const BulkHomePage = lazy(() => import('src/pages/enrichment/bulks'))
const BulkDetailsPage = lazy(() => import('src/pages/enrichment/bulks/[id]'))
const EnrichmentHistoryPage = lazy(() => import('src/pages/enrichment/history'))
const EnrichmentHistoryInfoPage = lazy(() => import('src/pages/enrichment/history/info'))

// hr engine
const HrEngineScreen = lazy(() => import('src/pages/hr/search'))

// business directory
const BusinessDirectorySearchScreen = lazy(() => import('src/pages/business-directory/search'))
const BusinessDirectoryOrdersScreen = lazy(() => import('src/pages/business-directory/orders'))
const OrderSuccessPaymentScreen = lazy(() => import('src/pages/business-directory/order/success_payment'))
const OrderFailedPaymentScreen = lazy(() => import('src/pages/business-directory/order/failed_payment'))

// integrations
const IntegrationsListScreen = lazy(() => import('src/pages/integrations/list'))
const ZapierIntegrationScreen = lazy(() => import('src/pages/integrations/zapier'))
const HubspotScreen = lazy(() => import('src/pages/integrations/hubspot/integration'))
const HubspotSuccessScreen = lazy(() => import('src/pages/integrations/hubspot/success'))
const HubspotFailedScreen = lazy(() => import('src/pages/integrations/hubspot/failed'))
const SalesforceScreen = lazy(() => import('src/pages/integrations/salesforce/integration'))
const SalesforceSuccessScreen = lazy(() => import('src/pages/integrations/salesforce/success'))
const ZohoScreen = lazy(() => import('src/pages/integrations/zoho/integration'))
const ZohoSuccessScreen = lazy(() => import('src/pages/integrations/zoho/success'))

// invoice
const InvoicesListScreen = lazy(() => import('src/pages/invoices/list'))
const InvoicesInfoScreen = lazy(() => import('src/pages/invoices/invoice'))

// lists
const ListsScreen = lazy(() => import('src/pages/lists/list'))
const ListInfoScreen = lazy(() => import('src/pages/lists/info'))

// plans
const PlansScreen = lazy(() => import('src/pages/plans'))
const SuccessPaymentScreen = lazy(() => import('src/pages/plans/success-payment'))
const FailedPaymentScreen = lazy(() => import('src/pages/plans/failed-payment'))

// payments
const CreditsSucessPaymentScreen = lazy(() => import('src/pages/payment/success-payment'))
const CreditsFailedPaymentScreen = lazy(() => import('src/pages/payment/failed-payment'))

// teams
const TeamsListScreen = lazy(() => import('src/pages/teams/list'))
const TeamMemberInfoScreen = lazy(() => import('src/pages/teams/member'))

// campaigns
const CampaignsListScreen = lazy(() => import('src/pages/campaigns/list'))
const NewCampaignScreen = lazy(() => import('src/pages/campaigns/new'))
const CampaignInfoScreen = lazy(() => import('src/pages/campaigns/info'))
const CampaignsEmailsManagementScreen = lazy(() => import('src/pages/campaigns/emails/list'))
const NewCampaignEmailScreen = lazy(() => import('src/pages/campaigns/emails/new'))
const EditCampaignEmailScreen = lazy(() => import('src/pages/campaigns/emails/edit'))

// tickets
const TicketsListScreen = lazy(() => import('src/pages/tickets/list'))
const NewTicketScreen = lazy(() => import('src/pages/tickets/new'))

// profile
const ProfileScreen = lazy(() => import('src/pages/profile'))
const ApiManagementScreen = lazy(() => import('src/pages/api-management'))

//test
const Test = lazy(() => import('src/pages/test/test'))

const panelRoutes: Array<RouteObject> = [
    {
        id: 'default-screen',
        index: true,
        element: <DefaultScreen />
    },
    {
        id: 'credits-usage-screen',
        path: 'credits-usage',
        element: (
            <Suspense fallback={<Spinner />}>
                <CreditUsageScreen />
            </Suspense>
        )
    },
    {
        id: 'enrichment-engine-group',
        path: 'enrichment/',
        children: [
            {
                id: 'enrichment-history',
                index: true,
                path: 'history',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <EnrichmentHistoryPage />
                    </Suspense>
                )
            },
            {
                id: 'enrichment-bulk-info',
                path: 'history/:id',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <EnrichmentHistoryInfoPage />
                    </Suspense>
                )
            },
            {
                id: 'bulks-list',
                index: true,
                path: 'bulks',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <BulkHomePage />
                    </Suspense>
                )
            }
            // {
            //     id: 'bulk-details',
            //     path: 'bulks/:id',
            //     element: (
            //         <Suspense fallback={<Spinner />}>
            //             <BulkDetailsPage />
            //         </Suspense>
            //     )
            // }
        ]
    },
    {
        id: 'hr-engine-screen',
        path: 'hr',
        element: (
            <Suspense fallback={<Spinner />}>
                <HrEngineScreen />
            </Suspense>
        )
    },
    {
        id: 'business-directory-group',
        path: 'business-directory',
        children: [
            {
                path: 'search',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <BusinessDirectorySearchScreen />
                    </Suspense>
                )
            },
            {
                path: 'orders',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <BusinessDirectoryOrdersScreen />
                    </Suspense>
                )
            },
            {
                path: 'order',
                children: [
                    {
                        path: 'success_payment',
                        element: (
                            <Suspense fallback={<Spinner />}>
                                <OrderSuccessPaymentScreen />
                            </Suspense>
                        )
                    },
                    {
                        path: 'failed_payment',
                        element: (
                            <Suspense fallback={<Spinner />}>
                                <OrderFailedPaymentScreen />
                            </Suspense>
                        )
                    }
                ]
            }
        ]
    },
    {
        id: 'integrations-group',
        path: 'integrations',
        children: [
            {
                id: 'integrations-list-screen',
                index: true,
                element: (
                    <Suspense fallback={<Spinner />}>
                        <IntegrationsListScreen />
                    </Suspense>
                )
            },
            {
                id: 'zapier-integration-screen',
                path: 'zapier',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <ZapierIntegrationScreen />
                    </Suspense>
                )
            },
            {
                id: 'hubspot-integration-group',
                path: 'hubspot',
                children: [
                    {
                        id: 'hubspot-integration-screen',
                        index: true,
                        element: (
                            <Suspense fallback={<Spinner />}>
                                <HubspotScreen />
                            </Suspense>
                        )
                    },
                    {
                        id: 'hubspot-success-connection-screen',
                        path: 'success_connection',
                        element: (
                            <Suspense fallback={<Spinner />}>
                                <HubspotSuccessScreen />
                            </Suspense>
                        )
                    },
                    {
                        id: 'hubspot-failure-connection-screen',
                        path: 'failed_connection',
                        element: (
                            <Suspense fallback={<Spinner />}>
                                <HubspotFailedScreen />
                            </Suspense>
                        )
                    }
                ]
            },
            {
                id: 'zoho-integration-group',
                path: 'zoho',
                children: [
                    {
                        id: 'zoho-integration-screen',
                        index: true,
                        element: (
                            <Suspense fallback={<Spinner />}>
                                <ZohoScreen />
                            </Suspense>
                        )
                    },
                    {
                        id: 'zoho-success-connection-screen',
                        path: 'success_connection',
                        element: (
                            <Suspense fallback={<Spinner />}>
                                <ZohoSuccessScreen />
                            </Suspense>
                        )
                    }
                ]
            },
            {
                path: 'salesforce',
                children: [
                    {
                        id: 'salesforce-integration-screen',
                        index: true,
                        element: (
                            <Suspense fallback={<Spinner />}>
                                <SalesforceScreen />
                            </Suspense>
                        )
                    },
                    {
                        id: 'salesforce-success-connection-screen',
                        path: 'success_connection',
                        element: (
                            <Suspense fallback={<Spinner />}>
                                <SalesforceSuccessScreen />
                            </Suspense>
                        )
                    }
                ]
            }
        ]
    },
    {
        id: 'invoices-group',
        path: 'invoices',
        children: [
            {
                id: 'invoices-list-screen',
                index: true,
                element: (
                    <Suspense fallback={<Spinner />}>
                        <InvoicesListScreen />
                    </Suspense>
                )
            },
            {
                id: 'invoice-screen',
                path: ':id',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <InvoicesInfoScreen />
                    </Suspense>
                )
            }
        ]
    },
    {
        id: 'lists-group',
        path: 'lists',
        children: [
            {
                id: 'lists-screen',
                index: true,
                element: (
                    <Suspense fallback={<Spinner />}>
                        <ListsScreen />
                    </Suspense>
                )
            },
            {
                id: 'list-screen',
                path: ':id',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <ListInfoScreen />
                    </Suspense>
                )
            }
        ]
    },
    {
        id: 'plans-group',
        path: 'plans',
        children: [
            {
                id: 'plans-list-screen',
                index: true,
                element: (
                    <Suspense fallback={<Spinner />}>
                        <PlansScreen />
                    </Suspense>
                )
            },
            {
                id: 'success-payment-screen',
                path: 'success_payment',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <SuccessPaymentScreen />
                    </Suspense>
                )
            },
            {
                id: 'failure-payment-screen',
                path: 'failed_payment',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <FailedPaymentScreen />
                    </Suspense>
                )
            }
        ]
    },
    {
        id: 'payment-screens-group',
        path: 'payment',
        children: [
            {
                id: 'credits-success-payment',
                path: 'success',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <CreditsSucessPaymentScreen />
                    </Suspense>
                )
            },
            {
                id: 'credits-failed-payment',
                path: 'failed',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <CreditsFailedPaymentScreen />
                    </Suspense>
                )
            }
        ]
    },
    {
        id: 'profile-screen',
        path: 'profile',
        element: (
            <Suspense fallback={<Spinner />}>
                <ProfileScreen />
            </Suspense>
        )
    },
    {
        id: 'api-management-screen',
        path: 'api',
        element: (
            <Suspense fallback={<Spinner />}>
                <ApiManagementScreen />
            </Suspense>
        )
    },
    {
        id: 'prospect-engine-group',
        path: 'prospect',
        children: [
            {
                id: 'company-search-screen',
                path: 'company',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <CompanySearchScreen />
                    </Suspense>
                )
            },
            {
                id: 'company-info-screen',
                path: 'company/:id',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <CompanyDetailsScreen />
                    </Suspense>
                )
            },
            {
                id: 'contact-search-screen',
                path: 'contact',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <ContactSearchScreen />
                    </Suspense>
                )
            },
            {
                id: 'contact-info-screen',
                path: 'contact/:id',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <ContactInfoScreen />
                    </Suspense>
                )
            },
            {
                id: 'corporation-info-screen',
                path: 'corporation/:id',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <CorporationDetailsScreen />
                    </Suspense>
                )
            },
            {
                id: 'bulk-search-screen',
                path: 'bulk',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <BulkSearchScreen />
                    </Suspense>
                )
            }
        ]
    },
    {
        id: 'teams-group',
        path: 'teams',
        children: [
            {
                id: 'teams-list-screen',
                index: true,
                element: (
                    <Suspense fallback={<Spinner />}>
                        <TeamsListScreen />
                    </Suspense>
                )
            },
            {
                id: 'team-member-info-screen',
                path: 'member/:email',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <TeamMemberInfoScreen />
                    </Suspense>
                )
            }
        ]
    },
    {
        id: 'campaigns-group',
        path: 'campaigns',
        children: [
            {
                id: 'campaigns-list-screen',
                index: true,
                element: (
                    <Suspense fallback={<Spinner />}>
                        <CampaignsListScreen />
                    </Suspense>
                )
            },
            {
                id: 'new-campaign-screen',
                path: 'new',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <CampaignProvider>
                            <NewCampaignScreen />
                        </CampaignProvider>
                    </Suspense>
                )
            },
            {
                id: 'campaign-info-screen',
                path: ':id',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <CampaignProvider>
                            <CampaignInfoScreen />
                        </CampaignProvider>
                    </Suspense>
                )
            },
            {
                id: 'campaigns-emails-management-group',
                path: 'emails',
                children: [
                    {
                        id: 'campaigns-emails-list-screen',
                        index: true,
                        element: (
                            <Suspense fallback={<Spinner />}>
                                <CampaignsEmailsManagementScreen />
                            </Suspense>
                        )
                    },
                    {
                        id: 'new-campaign-email-screen',
                        path: 'new',
                        element: (
                            <Suspense fallback={<Spinner />}>
                                <NewCampaignEmailScreen />
                            </Suspense>
                        )
                    },
                    {
                        id: 'edit-campaign-email-screen',
                        path: ':id',
                        element: (
                            <Suspense fallback={<Spinner />}>
                                <EditCampaignEmailScreen />
                            </Suspense>
                        )
                    }
                ]
            }
        ]
    },
    {
        id: 'tickets-group',
        path: 'tickets',
        children: [
            {
                id: 'tickets-list-screen',
                index: true,
                element: (
                    <Suspense fallback={<Spinner />}>
                        <TicketsListScreen />
                    </Suspense>
                )
            },
            {
                id: 'new-ticket-screen',
                path: 'new',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <NewTicketScreen />
                    </Suspense>
                )
            }
        ]
    },
    {
        id: 'test',
        path: 'test',
        element: <Test />
    }
]

export default panelRoutes
