import { useRef, useEffect } from 'react'
import { useIntercom as useReactIntercom } from 'react-use-intercom'
import useUser from 'src/store/user'
import type { Device, EventType } from 'src/types/Intercom'

const useIntercomWrapper = () => {
    const userId = useUser((state) => state.user?.analytics_user_id)
    const currentPlan = useUser((state) => state.getPlanName())
    const isFreePlan = useUser((state) => state.isFreePlan())
    const { update, trackEvent } = useReactIntercom()
    //?update will send http req with name 'ping' that we can check in network tab
    //?trackEvent will send http req with name 'event' that we can check in network tab
    const device = useRef<Device>(null!) //useful for those events that needed device as payload
    const timezone = useRef<string>(null!)
    const sendIntercomEvent = ({ event, data }: EventType) => {
        trackEvent(event, {
            userId: userId,
            is_paid_user: !isFreePlan,
            current_plan: currentPlan,
            timestamp: new Date(),
            timezone: timezone.current,
            device: device.current,
            ...data
        })
    }
    useEffect(() => {
        //* Set user timezone ----------------------
        timezone.current = Intl.DateTimeFormat().resolvedOptions().timeZone
        //* Set user agent device ----------------------
        let userDevice: Device
        const ua = navigator.userAgent
        if (/mobile/i.test(ua)) userDevice = 'mobile'
        else if (/tablet/i.test(ua)) userDevice = 'tablet'
        else userDevice = 'desktop'
        device.current = userDevice
    }, [])

    return { sendIntercomEvent }
}

export default useIntercomWrapper
