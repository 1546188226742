import { type ReactNode } from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Card, { type CardProps } from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Icon from 'src/components/shared/Icon'
import CreditsOverview from 'src/components/commons/CreditsOverview'

export type Overview = {
    title: string
    date: null | string
    description?: string
    icon?: string
    iconColor?: string
    credits?: number
    children?: ReactNode
}
type Props = CardProps & Overview

export default function OverviewCard({
    title,
    date,
    description,
    icon = 'solar:pen-new-square-outline',
    iconColor = 'newPrimary',
    credits = 0,
    children,
    sx,
    ...rest
}: Props) {
    const hasDescription = !!description
    const hasCredits = !!credits

    return (
        <Card variant='outlined' sx={{ px: 6, py: 4, bgcolor: 'white', ...sx }} {...rest}>
            <Stack direction='row' justifyContent='space-between' alignItems='center' gap={6}>
                <Stack direction='row' alignItems='center' gap={3}>
                    <Stack
                        width={45}
                        borderRadius='50%'
                        border={1}
                        borderColor='newGrey.light3'
                        justifyContent='center'
                        alignItems='center'
                        flexShrink={0}
                        sx={{ aspectRatio: '1 / 1' }}
                    >
                        <Icon icon={icon} color={iconColor} size='lg' />
                    </Stack>
                    <Box>
                        <Typography component='p' variant='newLabelLg' color='newNeutral'>
                            {title}
                        </Typography>
                        {!!date && (
                            <Typography component='p' variant='newBodySm' color='newNeutral.light2' mt={2}>
                                {date}
                            </Typography>
                        )}
                    </Box>
                </Stack>
            </Stack>
            <Divider sx={{ mt: 3, mb: 4, borderColor: 'newNeutral.light5' }} />
            <Box>
                {(hasDescription || hasCredits) && (
                    <Stack direction='row' justifyContent='space-between' alignItems='center' gap={3} flexWrap='wrap'>
                        {hasDescription && (
                            <Typography component='p' variant='newBodySm' color='newNeutral.light2'>
                                {description}
                            </Typography>
                        )}
                        {hasCredits && (
                            <Box
                                display='inline-block'
                                border={1}
                                borderColor='newGrey.main'
                                borderRadius={4}
                                pl={0.5}
                                pr={2}
                                py={0.5}
                            >
                                <CreditsOverview count={credits} suffix='Credits Usage' />
                            </Box>
                        )}
                    </Stack>
                )}
                {children}
            </Box>
        </Card>
    )
}
