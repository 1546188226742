// ** react imports
import { FC, useEffect } from 'react'

// ** react router imports
import { useLocation } from 'react-router-dom'

// ** store imports
import useUser from 'src/store/user'

// ** mui imports
import Stack, { StackProps } from '@mui/material/Stack'
import { styled } from '@mui/material/styles'

// ** custom components imports
import Button from 'src/components/Button'
import Typography from 'src/components/Typography'

// ** third party imports
import { useMutation } from '@tanstack/react-query'
import { Helmet } from 'react-helmet-async'

// ** layout imports
import FooterIllustrations from 'src/components/FooterIllustrations'

// ** common service
import CommonService from 'src/services/common.service'

// ** configs imports
import themeConfig from 'src/configs/themeConfig'

// ** styled components
const BoxWrapper = styled(Stack)<StackProps>(({ theme }) => ({
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
        width: '90vw'
    }
}))

const Img = styled('img')(({ theme }) => ({
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(15),
    [theme.breakpoints.down('lg')]: {
        height: 450,
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(10)
    },
    [theme.breakpoints.down('md')]: {
        height: 400
    }
}))

const commonApi = new CommonService()

const AppCrashPage: FC = () => {
    // ** store
    const user = useUser((state) => state.user)

    // ** hooks
    const location = useLocation()
    const { mutate: handleSendCrashErr } = useMutation({
        mutationKey: ['crash_analytics_event'],
        mutationFn: (pageUrl: string) =>
            commonApi.newEvent('viewed app crash error page', {
                metaData: {
                    url: pageUrl
                }
            })
    })

    useEffect(() => {
        handleSendCrashErr(location.pathname)
    }, [])

    // ** methods
    const handleHomeRoute = () => {
        if (!user) return '/auth/signin'
        return '/'
    }

    return (
        <Stack className='content-center'>
            <Helmet>
                <title>{`Ooops, Something went wrong | ${themeConfig.templateName}`}</title>
            </Helmet>
            <Stack alignItems='center' textAlign='center' p={5}>
                <BoxWrapper>
                    <Typography variant='h1' sx={{ mb: 2.5 }}>
                        Ooops
                    </Typography>
                    <Typography variant='h5' sx={{ mb: 2.5, fontSize: '1.5rem !important' }}>
                        Something went wrong 👨🏻‍💻
                    </Typography>
                    <Typography variant='body2'>
                        This will be reported, You can go back to the previous page or refresh the page.
                    </Typography>
                </BoxWrapper>
                <Img alt='error-illustration' src='/images/pages/500.png' />
                <Button href={handleHomeRoute()} sx={{ px: 5.5 }}>
                    Back to Home
                </Button>
            </Stack>
            <FooterIllustrations image='/images/pages/misc-500-object.png' />
        </Stack>
    )
}

export default AppCrashPage
