import axios from 'axios'
import { setAuthToken, handleResponseError } from '../utils'

const v1Axios = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_API_URL}api/v1`
})
v1Axios.interceptors.request.use(
    (config) => {
        //? Do something before request is sent
        setAuthToken(config) //set Authorization header
        return config
    },
    (error) => {
        //? Do something with request error
        return Promise.reject(error)
    }
)
v1Axios.interceptors.response.use(
    (response) => {
        //? Do something with response data if response status code is 2xx(success)
        return response
    },
    (error) => {
        //? Do something with response data if response status code is outside of 2xx(error)
        handleResponseError(error) //handle show error toast,logout,redirect,...
        return Promise.reject(error)
    }
)

export default v1Axios
