import { LicenseInfo } from '@mui/x-license'
import { DataGridPro, type DataGridProProps, type GridValidRowModel } from '@mui/x-data-grid-pro'
import useColor from 'src/hooks/useColor'

LicenseInfo.setLicenseKey(
    'ca0fb2b239c82d68b1e1bdf5449350a8Tz0xMDA3MzEsRT0xNzYxNTUzODYzMDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI='
)

export type Props<Row extends GridValidRowModel> = DataGridProProps<Row> & {
    color?: string
    borderColor?: string
    checkboxColor?: 'primary' | 'newPrimary' | 'newEnrichment'
}

const BaseMuiDataGrid = <Row extends GridValidRowModel>({
    rowHeight = 52,
    slots,
    slotProps,
    color = 'newPrimary',
    borderColor = 'rgb(224,224,224)',
    checkboxColor = 'newPrimary',
    sx,
    ...rest
}: Props<Row>) => {
    const parsedColor = useColor(color)

    return (
        <DataGridPro
            rowHeight={rowHeight}
            slots={{ ...slots }}
            slotProps={{
                baseCheckbox: {
                    color: checkboxColor
                },
                panel: {
                    sx: {
                        '& .MuiDataGrid-filterForm .MuiTextField-root': {
                            height: 1,
                            '& .MuiInputBase-root': {
                                height: 1
                            }
                        }
                    }
                },
                ...slotProps
            }}
            sx={{
                //? reset those styles that are set in @core/theme/overrides/datagrid
                border: `1px solid ${borderColor}`,
                borderRadius: 0,
                color: 'rgba(0, 0, 0, 0.87)',
                '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within': {
                    outline: `1px solid ${parsedColor}`
                },
                '& .MuiDataGrid-toolbarContainer': {
                    p: 2
                },
                '& .MuiDataGrid-columnHeaders': {
                    minHeight: 'initial !important',
                    maxHeight: 'initial !important',
                    lineHeight: 'initial !important',
                    backgroundColor: 'transparent'
                },
                '& .MuiDataGrid-columnHeader': {
                    height: 'auto',
                    '&:not(.MuiDataGrid-columnHeaderCheckbox)': {
                        px: 2.5,
                        py: 0,
                        '&:first-of-type': {
                            paddingLeft: 'initial'
                        }
                    },
                    '&:last-of-type': {
                        paddingRight: 'initial'
                    }
                },
                '& .MuiDataGrid-columnHeaderCheckbox': {
                    maxWidth: 'initial !important',
                    minWidth: 'initial !important'
                },
                '& .MuiDataGrid-columnHeaderTitleContainer': {
                    padding: 'initial'
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                    fontSize: '.875rem',
                    color: 'newNeutral',
                    letterSpacing: 'initial',
                    textTransform: 'capitalize'
                },
                '& .MuiDataGrid-columnSeparator': {
                    color: borderColor
                },
                '& .MuiDataGrid-virtualScroller': {
                    marginTop: 'initial !important'
                },
                '& .MuiDataGrid-virtualScrollerRenderZone': {
                    '& .MuiDataGrid-row': {
                        minHeight: 'initial !important',
                        maxHeight: 'initial !important'
                    }
                },
                '& .MuiDataGrid-cell': {
                    minHeight: 'initial !important',
                    maxHeight: 'initial !important',
                    lineHeight: 'initial !important',
                    borderColor: borderColor,
                    display: 'flex',
                    alignItems: 'center',
                    '&:not(.MuiDataGrid-cellCheckbox)': {
                        px: 2.5,
                        py: 0,
                        '&:first-of-type': {
                            paddingLeft: 'initial'
                        }
                    },
                    '&:last-of-type': {
                        paddingRight: 'initial'
                    },
                    '&:focus, &:focus-within': {
                        outline: `1px solid ${parsedColor}`
                    }
                },
                '& .MuiDataGrid-cellCheckbox': {
                    minWidth: 'initial !important',
                    maxWidth: 'initial !important'
                },
                '& .MuiDataGrid-editInputCell': {
                    padding: 0.5,
                    color: (theme) => theme.palette.newNeutral.main,
                    '& .MuiInputBase-input': {
                        px: 4,
                        py: 0
                    }
                },
                '& .MuiDataGrid-footerContainer': {
                    minHeight: '52px !important',
                    borderTop: `1px solid ${borderColor}`,
                    '& .MuiTablePagination-toolbar': {
                        minHeight: '52px !important'
                    },
                    '& .MuiTablePagination-select': {
                        color: 'initial'
                    }
                },
                ...sx
            }}
            {...rest}
        />
    )
}

export default BaseMuiDataGrid

//? Example:
// import {
//     GridToolbar,
//     type GridColDef,
//     type GridFilterModel,
//     type GridPaginationModel,
//     type GridRowSelectionModel
// } from '@mui/x-data-grid-pro'
// const [filters, setFilters] = useState<GridFilterModel>({ items: [], quickFilterValues: [''] })
// const [selections, setSelections] = useState<GridRowSelectionModel>([])
// <Textfield
//     value={filters.quickFilterValues}
//     onChange={(e) =>setFilters((old) => ({ ...old, quickFilterValues: [e.target.value || ''] }))}
// />
// <Box mt={5} height={rows.length < 15 ? 'auto' : 750}>
//     //* manually add height to container to enable virutalization
//     <BaseMuiDataGrid
//         getRowId={(row) => row._id} columns={columns} rows={rows} getRowHeight={(row) => 'auto'}
//         filterMode='client' filterModel={filters} onFilterModelChange={(newVal) => setFilters(newVal)}
//         paginationMode='client' pagination={false}
//         checkboxSelection disableRowSelectionOnClick rowSelectionModel={selections} onRowSelectionModelChange={(newVal) => setSelections(newVal)}
//         slots={{toolbar: GridToolbar}}
// /></Box>
