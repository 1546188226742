import { type ReactNode } from 'react'
import Dialog, { type DialogProps } from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Button from 'src/components/shared/Button'
import Icon from 'src/components/shared/Icon'

type Props = Omit<DialogProps, 'open' | 'maxWidth'> & {
    open: boolean
    onOpenChange?: (newOpen: boolean) => void
    title?: string
    children?: ReactNode
    btnText?: string
    btnColor?: 'newPrimary' | 'newError' | 'newNeutral'
    closable?: boolean
    cancelable?: boolean
    loading?: boolean
    width?: number | string
    maxWidth?: number | string
    onCancel?: () => void
    onDelete?: () => void
}

export default function DeleteDialog({
    open = false,
    onOpenChange,
    title = 'Delete Item',
    children, //act as description of dialog
    btnText = 'Delete',
    btnColor = 'newError',
    closable = true,
    cancelable = true,
    loading = false,
    width = 560,
    maxWidth = '90vw',
    onCancel,
    onDelete,
    ...rest
}: Props) {
    const onCloseHandler = () => {
        if (onOpenChange) onOpenChange(false)
    }

    return (
        <Dialog open={open} onClose={onCloseHandler} PaperProps={{ sx: { width, maxWidth, border: 'none' } }} {...rest}>
            <DialogContent sx={{ p: 0 }}>
                <Card sx={{ bgcolor: 'white' }}>
                    <CardContent>
                        <Stack direction='row' justifyContent='space-between' alignItems='center' gap={2}>
                            <Typography component='h5' variant='newLabelLg' color='newNeutral'>
                                {title}
                            </Typography>
                            {closable && (
                                <Button variant='text' size='sm' sx={{ p: 1, minWidth: 0 }} onClick={onCloseHandler}>
                                    <Icon icon='mdi:close' size='md' color='newNeutral.light3' />
                                </Button>
                            )}
                        </Stack>
                        {/* <Divider sx={{ my: 4, borderColor: 'newNeutral.light5' }} /> */}
                        <Box mt={5} typography='newBodyMd' color='newNeutral.light2'>
                            {children || 'Are you sure that you want to delete selected item ?'}
                        </Box>
                    </CardContent>
                    <CardActions sx={{ mt: 2, justifyContent: 'center', gap: 2 }}>
                        <Stack width={1} direction='row' justifyContent='flex-end' gap={3}>
                            {cancelable && (
                                <Button
                                    variant='outlined'
                                    size='xs'
                                    color='newNeutral'
                                    sx={{
                                        px: 8,
                                        borderColor: 'newNeutral.light5',
                                        boxShadow: 1,
                                        '&:hover': { boxShadow: 1 }
                                    }}
                                    onClick={onCloseHandler}
                                >
                                    Cancel
                                </Button>
                            )}
                            <Button
                                variant='contained'
                                size='xs'
                                color={btnColor}
                                loading={loading}
                                sx={{ px: 8 }}
                                onClick={onDelete}
                            >
                                {btnText}
                            </Button>
                        </Stack>
                    </CardActions>
                </Card>
            </DialogContent>
        </Dialog>
    )
}
