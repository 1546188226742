import { useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import Container from 'src/components/shared/Container'
import Meta from 'src/components/shared/Meta'
import EnrichTable from 'src/components/new-enrichment/enrich/Table'

export default function EnrichmentBulkPage() {
    const params = useParams()
    const bulkId = +params.id! as number

    return (
        <Box>
            <Meta title='Bulks Infos | Enrichment Engine | CUFinder' />
            <Container mode='stretch'>
                <EnrichTable bulkId={bulkId} />
            </Container>
        </Box>
    )
}
