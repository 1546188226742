import type { BulkResponse, CellData, Bulk, EnrichResponse, Enrich } from 'src/types/enrichment'
import moment from 'moment'

export const cellsDataNormalize = (data: CellData[]) => {
    let headers: string[] = []
    let rows: unknown[][] = []
    try {
        //its possible that each CellData has its own keys and we should use null for those keys that we don't have them
        //e.g for [{_id:1,name:'n'},{_id:2,age:10}] output should be: [{_id:1,name:'n',age:null},{_id:2,name:null,age:10}]
        if (data.length) {
            headers = Array.from(new Set(data.map((d) => Object.keys(d)).flat())).filter((h) => h !== '_id')
            rows = data.map((d) => {
                const row: { [key: string]: null | unknown } = {}
                headers.forEach((header) => {
                    row[header] = d[header] || null
                })
                return Object.values(row)
            })
        }
    } catch (e) {}

    return [headers, ...rows]
}
export const bulkResponseToClient = ({
    bulk_id,
    creation_date,
    last_update,
    type,
    current_service,
    status,
    name,
    rows_count,
    cells_data = []
}: BulkResponse): Bulk => {
    return {
        id: bulk_id,
        createdAt: moment(creation_date).format('YYYY/MM/DD HH:mm A'),
        updatedAt: moment(last_update).format('YYYY/MM/DD HH:mm A'),
        type,
        code: current_service || undefined,
        status,
        name,
        rowCount: rows_count,
        cellsData: cells_data,
        cellsDataNormalize: cellsDataNormalize(cells_data)
    }
}

export const enrichResponseToClient = ({
    bulk_id,
    service,
    passed,
    rows_count,
    used_credits,
    start_time,
    end_time
}: EnrichResponse): Enrich => {
    return {
        bulkId: bulk_id,
        serviceCode: service,
        passed,
        rowCounts: rows_count,
        creditsUsage: used_credits || 0,
        startDate: start_time ? moment(start_time).format('YYYY/MM/DD HH:mm A') : null,
        endDate: end_time ? moment(end_time).format('YYYY/MM/DD HH:mm A') : null
    }
}
