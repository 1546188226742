import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import Stack from '@mui/material/Stack'
import Button from 'src/components/Button'
import Icon from 'src/components/Icon'
import UserDropdown from 'src/@core/layouts/components/shared-components/UserDropdown'
import NotificationsDropdown from 'src/@core/layouts/components/shared-components/NotificationsDropdown'
import CreditsOverview from 'src/components/commons/CreditsOverview'
import { Settings } from 'src/@core/context/settingsContext'
import useUser from 'src/store/user'
import { getCredits } from 'src/services/new-credits'
import { connect, disconnect, onGetCredits } from 'src/services/socket/credits'

interface Props {
    hidden: boolean
    settings: Settings
    saveSettings: (values: Partial<Settings>) => void
}

const AppBarContent = ({ settings }: Props) => {
    const isAuth = useUser((user) => user.isAuth())
    const credits = useUser((user) => user.user?.credits || 0)
    const updateCredits = useUser((user) => user.updateCredits)
    useQuery({
        enabled: isAuth,
        initialData: 0,
        queryKey: ['get-credits'],
        queryFn: async () => {
            const credits = await getCredits()
            updateCredits(credits)
            return credits
        }
    })
    useEffect(() => {
        if (isAuth) {
            connect(() => {
                onGetCredits((newCredits) => {
                    updateCredits(newCredits)
                })
            })
            return () => {
                disconnect()
            }
        }
    }, [isAuth])

    return (
        <Stack direction='row' alignItems='center' columnGap={2}>
            <CreditsOverview count={credits} mr={4} />
            <Button
                color='secondary'
                href='/lists'
                startIcon={<Icon icon='mdi:clipboard-list-outline' />}
                size='small'
                sx={{ minWidth: '0!important', width: '6rem' }}
            >
                lists
            </Button>
            <Button
                id='appbar-upgrade-btn'
                href='/plans'
                color='success'
                startIcon={<Icon icon='carbon:upgrade' />}
                size='small'
                sx={{ minWidth: '0!important', width: '6rem' }}
            >
                upgrade
            </Button>
            <NotificationsDropdown settings={settings} />
            <UserDropdown settings={settings} />
        </Stack>
    )
}

export default AppBarContent
