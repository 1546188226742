// ** socket imports
import socketManager from './manager'

// ** models imports
import { NotificationModel } from 'src/shared/models'

// ** third party imports
import { BehaviorSubject } from 'rxjs'

// ** configs imports
import authConfig from 'src/configs/auth'

// initializes notifications socket
const socket = socketManager.socket('/notifications', {
    auth: (callback) => {
        const token = localStorage.getItem(authConfig.storageTokenKeyName)
        callback({ token })
    }
})

// connects to the socket
export const connect = () => {
    const notifications = new BehaviorSubject<Array<any>>([])

    socket.connect()
    socket.on('connect', () => {
        // receives all notifications list
        socket.emit('get_list', (data: Array<NotificationModel>) => {
            notifications.next(data)
        })
    })

    socket.on('check_new_notification_alert', () => {
        // receives all notifications list
        socket.emit('get_list', (data: Array<NotificationModel>) => {
            notifications.next(data)
        })
    })
    return notifications
}

// disconnects from the socket
export const disconnect = () => {
    socket.disconnect()
}

// mark a notification as read
export const readNotification = (notificationId: number) => {
    const data = { notification_id: notificationId }
    socket.emit('read', data)
}

// mark all notifications as read
export const readAllNotifications = () => {
    socket.emit('read_all')
}
